import * as React from 'react';
import { LabelMedium, LabelSmall } from 'baseui/typography';
import { StatefulTooltip } from 'baseui/tooltip';
import Overflow from 'baseui/icon/overflow';
import { useStyletron } from 'baseui';
import { StatefulPopover, PLACEMENT, TRIGGER_TYPE } from 'baseui/popover';
import { StatefulMenu, NestedMenus } from 'baseui/menu';
import Toggle from 'react-toggle';
import { Search } from 'baseui/icon';
import { Input, SIZE } from 'baseui/input';
import { showAlert } from '../../redux/actions/appBasicControls';
import 'react-toggle/style.css';
import './card.css';
import store from '../../redux/store';
import settingsIcon from './images/settings.png';
import sidebar from './images/sidebar.png'
import { connect } from 'react-redux';
import RenderIcon from '../icons/renderIcon';
import styled from '@emotion/styled';
import svgIcons from '../../svg';
import PropTypes from 'prop-types';

const CustomIcon = styled.img`
  width: 63px;
  font-weight: lighter;
  z-index: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

const BaseImageUrl =
  '';

const MainCardDiv = styled.div`
  background-image: ${props => props.src};
  position: relative;
  border: ${props => props.isOpen ? '3px solid #7A5AF8' : 'none'};
  width: ${props => props.width};
  height: ${props => props.height};
  background-size: ${props => props.full_bleed ? 'cover' : 'contain'};
  background-position: center;
  background-repeat: no-repeat;
`;

function formatCount(count) {
  if (count === 0) return 0;
  if (`${count}`.length == 1) return `0${count}`;
  return count;
}

const CardComponent = props => {
  const {
    title,
    count,
    toggle,
    imageSrc,
    custom_icon,
    deck,
    channels,
    decks,
    isOpen,
    tabFilter,
    onToggleClick,
    onClone,
    onMoveDeck,
    onArchive,
    onUnArchive,
    onMoveCard,
    onMoveSlide,
    onSettingsClick,
    onCardClick,
    showSettingsIcon = true,
    isDeck,
    isQuizType,
    componentType,
    organization_color_palette_index = 0,
    organization,
    showMenuOptions = true,
    width,
    height,
    full_bleed = false,
    cover_type,
    icon_name,
    iconSource,
    isHomeComponent,
    currentSlideComponent,
    home_component_app_content_scopes,
    isCopyCard,
    showPublishToggle,
    cardType,
    onDeleteCard,
    showArchiveOption,
    onRemoveFromDeck,
    isTemplate,
    templateCategories
  } = props;

  const [css, theme] = useStyletron();
  const selectedChannel = store.getState().appBasicControls.selectedChannel;
  const categories = store.getState().appBasicControls.category;
  const [searchText, setSearchText] = React.useState('');
  const [newChildItems, setChildItems] = React.useState([]);
  const [isLoading, setLoading] = React.useState(false);

  React.useEffect(() => {
    setLoading(false);
  }, [toggle])

  React.useEffect(() => {
    // console.log('CardComponent useEffect', { isTemplate, templateCategories, categories, deck })
    let childItems = [];
    if (isHomeComponent) {
      childItems = home_component_app_content_scopes.map(item => ({
        label: item.home_component.title,
        selectedComponent: item
      })).filter(item => item.selectedComponent.home_component.id !== currentSlideComponent.home_component.id)
        .filter(item => {
          if (componentType && componentType !== item.selectedComponent.home_component.component_type) {
            return false;
          }
          return true
        })
        ; // except selfComponent
    } else if (isDeck) {
      childItems = channels
        ? channels
          .map(item => ({ label: item.name, selectedChannel: item }))
          .filter(item =>
            selectedChannel ? item.label !== selectedChannel.name : true
          )
        : [];
    } else {
      childItems = {};
      const categList = isTemplate ? templateCategories || [] : categories;
      const newItems = decks
        ? categList
          .map(({ category_id, category }) => {
            const { name, id } = category;
            let subDecks = [];
            let _id = isTemplate ? id : category_id;
            decks.map(item => {
              if (
                _id === item.category_id &&
                item?.deck_category_deck &&
                deck?.deck_category_deck &&
                // item.deck_category_deck.title !==
                // deck.deck_category_deck.title
                item.deck_category_deck.id !==
                deck.deck_category_deck.id
              ) {
                subDecks.push({
                  label: item.deck_category_deck.title,
                  selectedDeck: item,
                });
              }
            });
            if (subDecks.length > 0) {
              if (searchText !== '') {
                subDecks = subDecks.filter(
                  item => item.label.toLowerCase().indexOf(searchText) !== -1
                );
              }
              childItems[name] = subDecks;
              return {
                [name]: subDecks,
              };
            }
            return false;
          })
          .filter(item => item !== false)
        : [];
    }
    if (searchText !== '')
      if (isDeck) {
        childItems = childItems.filter(
          item => item.label.toLowerCase().indexOf(searchText) !== -1
        );
      }
    setChildItems(childItems);
  }, [searchText]);

  const filter = e => {
    const value = e.target.value;
    setSearchText(value.toLowerCase());
  };

  const onMenuItemClick = ({ item, event }) => {
    if (item.label === 'Settings') {
      onSettingsClick(event);
    }
    else if (item.label === 'Clone') {
      onClone(event);
    }
    else if (item.label === 'Archive') {
      onArchive(event);
    }
    else if (item.label === 'UnArchive') {
      onUnArchive(event);
    } else if (item.label === 'Delete') {
      onDeleteCard(event)
    }
    else if (item.label == 'Remove from Deck') {
      onRemoveFromDeck(event)
    }
  }

  let menuItems = [
    { label: 'Settings' },
    { label: 'Move ->' },
    { label: 'Clone' },
    { label: tabFilter && tabFilter === 'archived' ? 'UnArchive' : 'Archive' },
  ];

  if (tabFilter === 'archived') {
    menuItems = [
      { label: 'Settings' },
      { label: 'Move ->' },
      { label: tabFilter && tabFilter === 'archived' ? 'UnArchive' : 'Archive' },
    ];
  }

  let imageGradientColor = '#FFFFFF';
  if (icon_name || custom_icon) {
    imageGradientColor = '#52535C'
  }

  const menuOptions = React.useMemo(() => {
    if (cardType === 'single_card') {
      if (showArchiveOption) {
        return [
          { label: 'Delete' },
          { label: tabFilter && tabFilter === 'archived' ? 'UnArchive' : 'Archive' }
        ]
      }
      return [
        { label: 'Delete' }
      ]
    }
    return [
      { label: 'Settings' },
      { label: 'Move ->' },
      // { label: 'Clone' },
      { label: 'Remove from Deck' },
      { label: tabFilter && tabFilter === 'archived' ? 'UnArchive' : 'Archive' },
    ]
  }, [cardType])
  return (
    <div style={{ position: 'relative' }}>
      <div className="overlap-layer card-new-gradient-style" style={{
        width: width,
        height: height,
        border: isOpen ? '3px solid #7A5AF8' : 'none',
        position: 'absolute',
        background: organization ? organization['color_palette'][organization_color_palette_index] : 'black',
        left: '8px',
        top: '8px',
        borderRadius: '5px'
      }}></div>
      <MainCardDiv
        onClick={() => {
          if (onCardClick) {
            onCardClick();
          }
        }}
        src={`linear-gradient(180deg, rgba(0, 0, 0, 0.17), hsla(235, 32%, 52%, 0.514)), url("${imageSrc || BaseImageUrl}"), linear-gradient(0deg, rgb(255, 255, 255), rgb(255, 255, 255))`}
        isOpen={isOpen}
        width={width}
        height={height}
        full_bleed={full_bleed}
        className={'card-new-gradient-style'}
      >
        {cover_type == 'icon' && icon_name && <RenderIcon iconName={icon_name}></RenderIcon>}
        {cover_type == 'icon' && custom_icon && <CustomIcon src={custom_icon} />}
        {isCopyCard && <CustomIcon src={svgIcons.CopyCardIcon} />}
        {count !== undefined && (
          <div
            className={css({
              position: 'absolute',
              width: theme.sizing.scale950,
              height: theme.sizing.scale950,
              left: theme.sizing.scale300,
              top: theme.sizing.scale1000,
              // background: theme.leftNavBackgroundColor,
              background: 'transparent',
              // color: theme.colors.primaryA
            })}
          >
            <LabelSmall
              overrides={{
                LabelMedium: {
                  style: {
                    color: 'white',
                  },
                },
              }}
              className={css({
                color: 'white !important',
                textAlign: 'center',
                cursor: 'pointer',
                fontSize: '10px',
                fontWeight: '700',
                fontFamily: 'IBM Plex Sans !important',
                lineHeight: theme.sizing.scale900,
                background: 'black',
                borderRadius: '5px'

              })}
            >
              {formatCount(count)}
            </LabelSmall>
          </div>
        )}
        {
          showPublishToggle && (
            <StatefulTooltip
              content={() => (
                <div padding={'20px'}>{toggle ? 'Toggle off to Publish' : 'Toggle on to Publish'}</div>
              )}
              returnFocus
              autoFocus
            >
              <div
                className={css({
                  position: 'absolute',
                  width: theme.sizing.scale1200,
                  // height: theme.sizing.scale1200,
                  left: theme.sizing.scale300,
                  top: theme.sizing.scale0,
                })}
                onClick={e => {
                  e.preventDefault();
                  e.stopPropagation();
                }}
              >
                <Toggle
                  id="cheese-status"
                  checked={toggle}
                  icons={false}
                  onClick={e => {
                    e.preventDefault();
                    e.stopPropagation();
                  }}
                  onChange={e => {
                    console.log('toggle change')
                    if (isLoading) {
                      return true;
                    }
                    e.preventDefault();
                    e.stopPropagation();
                    if (tabFilter === "archived") {
                      return props.dispatch(
                        showAlert({
                          msg: 'Archived content can\'t be published!',
                          error: true,
                        })
                      );
                    }
                    setLoading(true);
                    onToggleClick(e);
                  }}
                />
              </div>
            </StatefulTooltip>
          )
        }

        {true && (
          <div
            className={css({
              position: 'absolute',
              width: '100%',
              left: 0,
              right: 0,
              bottom: '8px',
              height: '42px',
              whiteSpace: 'initial',
              // zIndex: 2
            })}
          >
            <LabelSmall
              className={css({
                color: 'white !important', //theme.colors.primaryA'',
                lineHeight: '13px',
                cursor: 'pointer',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                display: '-webkit-box',
                fontFamily: 'Roboto !important',
                '-webkit-line-clamp': 2,
                '-webkit-box-orient': 'vertical',
                position: 'relative',
                float: 'left',
                height: '100%',
                boxSizing: 'border-box',
                padding: '8px',
                fontWeight: '400',
                fontSize: '11px',
                // bottom: 0,
              })}
            >
              <StatefulTooltip
                content={() => (
                  <div>{title}</div>
                )}
                returnFocus
                autoFocus
              >
                {title.length > 15 ? title.substring(0, 15) + '...' : title}
              </StatefulTooltip>
            </LabelSmall>
          </div>
        )}
        {showMenuOptions && <div
          className={css({
            position: 'absolute',
            width: theme.sizing.scale1200,
            height: theme.sizing.scale1200,
            right: theme.sizing.scale300,
            top: theme.sizing.scale0,
          })}
          onClick={e => {
            e.preventDefault();
            e.stopPropagation();
          }}
        >
          <StatefulPopover
            initialState={{ isOpen: false }}
            dismissOnEsc={true}
            dismissOnClickOutside={true}
            overrides={{
              Body: {
                style: {
                  zIndex: !isDeck ? 3 : 'unset'
                }
              }
            }}
            content={() => (
              <NestedMenus>
                <StatefulMenu
                  items={menuOptions}
                  overrides={{
                    List: {
                      style: {
                        width: '120px',
                        maxHeight: '1000px',
                        overflow: 'auto',
                      },
                    },
                    Option: {
                      props: {
                        getChildMenu: item => {
                          if (item.label === 'Move ->') {
                            return (
                              <div style={{ maxHeight: '250px', zIndex: 101 }}>
                                <Input
                                  value={searchText}
                                  onChange={e => filter(e)}
                                  placeholder={
                                    isHomeComponent ? 'Choose Component' :
                                      isDeck
                                        ? 'Choose Channel'
                                        : 'Choose Deck by Category'
                                  }
                                  clearOnEscape
                                  size={SIZE.compact}
                                  endEnhancer={<Search size="18px" />}
                                  overrides={{
                                    Input: {
                                      style: ({ $theme }) => ({
                                        outline: `none !important`,
                                        border: '0 !important',
                                        margin: '10px',
                                      }),
                                    },
                                  }}
                                />
                                <StatefulMenu
                                  items={newChildItems}
                                  onItemSelect={({ item, event }) => {
                                    if (isHomeComponent) {
                                      onMoveSlide(item.selectedComponent);
                                    } else if (isDeck) {
                                      onMoveDeck(item.selectedChannel);
                                    } else {
                                      onMoveCard(item.selectedDeck);
                                    }
                                  }}
                                  overrides={{
                                    List: {
                                      style: {
                                        width: '300px',
                                        height: '150px',
                                        overflow: 'auto',
                                        zIndex: 101,
                                      },
                                    },
                                    Option: {
                                      props: {
                                        getItemLabel: item => item.label,
                                      },
                                    },
                                  }}
                                />
                              </div>
                            );
                          }
                        },
                      },
                    },
                  }}
                  onItemSelect={onMenuItemClick}
                />
              </NestedMenus>
            )}
            placement={PLACEMENT.top}
          >
            <Overflow
              className={css({
                top: '-10px',
                right: '-7px',
                color: 'white !important',
                position: 'absolute',
                cursor: 'pointer',
                marginBottom: '0px',
              })}
              size={48}
            />
          </StatefulPopover>
        </div>}

        {isQuizType && <div
          className={css({
            position: 'absolute',
            width: theme.sizing.scale1200,
            height: '18px',
            right: '4px',
            bottom: '4px',
            background: '#5d64ab',
            color: 'white',
            textAlign: 'center',
            lineHeight: '18px',
            borderRadius: '2px',
            fontSize: '12px',
            textTransform: 'uppercase',
            fontWeight: 600
          })}
          onClick={e => {
            e.preventDefault();
            e.stopPropagation();
          }}
        >Quiz</div>}
      </MainCardDiv>
    </div>
  );
};

CardComponent.propTypes = {
  showPublishToggle: PropTypes.bool,
  onDeleteCard: PropTypes.func
}

CardComponent.defaultProps = {
  showPublishToggle: true,
  onDeleteCard: () => {

  }
}

const mapStateToProps = props => {
  let { organization } = props.appBasicControls;
  return {
    organization,
  };
};

export default connect(mapStateToProps)(CardComponent);
