import * as React from 'react';
import { Grid, Cell } from 'baseui/layout-grid';
import { LabelMedium, ParagraphSmall } from 'baseui/typography';
import { Input } from 'baseui/input';
import { useMutation } from '@apollo/client';
import { Checkbox, LABEL_PLACEMENT } from 'baseui/checkbox';
import { Select } from 'baseui/select';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalButton,
} from 'baseui/modal';
import { sourceByType } from '../../util';
import { updateDeckMutation, updateQuizDeckMutation } from '../../quries';
import store from '../../redux/store';
import SelectIcon from '../icons/index';
import RenderIcon from '../icons/renderIcon';
import ImageUploadCXL from '../imageUploaderCXL';
import { Tabs, Tab } from "baseui/tabs-motion";
import { deckCoverGuideline } from '../help/imageSizeGuidelines.json';
import titlesLength from '../../../data/titlesLength.json';
import { client } from '../../apollo/client';
import { apiQuery } from '../apiMappings/query';
import DeckApiMapping from '../apiMappings/deck-mapping';

const getSrc = data => sourceByType(data, 'cover_small_url');

const cardStyleOptions = [{
  label: 'Card Carousel (v1)',
  id: 'card_carousel_v1'
}, {
  label: 'List View (v1)',
  id: 'list_view_v1'
}, {
  label: 'Grid View (v1)',
  id: 'grid_view_v1'
}];

const cardTemplateOptions = [
  {
    title: 'v1',
    id: 'v1'
  }
]

export default ({ deck, position, category_id, organization_id, onClose, isQuizType }) => {
  const [isOpen, setIsOpen] = React.useState(true);
  const [isLoading, setIsLoading] = React.useState(false);

  const [title, setTitle] = React.useState(deck.title || '');
  const [description, setDescription] = React.useState(deck.description || '');
  const [full_bleed, setFullBleed] = React.useState(deck.full_bleed || false);
  const [home_featured, setHomeFeatured] = React.useState(
    deck.home_featured || false
  );
  const [organization_color_palette_index, setColorIndex] = React.useState(
    deck.organization_color_palette_index || 0
  );
  const [cover_image_data, setImage] = React.useState(
    deck.cover_image_data || null
  );
  const [cover_image, setUpload] = React.useState(null);
  const [preivew_image, setPreview] = React.useState(null);

  const [cover_type, set_cover_type] = React.useState(deck.cover_type || 'photo');
  const [icon_name, set_icon_name] = React.useState(deck.icon_name);

  const [isMappingOpen, setIsMappingOpen] = React.useState(false);
  const [has_data_source_connected, set_has_data_source_connected] = React.useState(deck.has_data_source_connected || false);
  const [show_answer_validation, set_show_answer_validation] = React.useState(deck.show_answer_validation || false);
  const [api_path_id, set_api_path_id] = React.useState(deck.api_path_id || null);
  const [paths, setPaths] = React.useState([]);
  const [cardStyle, setCardStyle] = React.useState(deck.card_style || 'card_carousel_v1');
  const [use_card_template, set_use_card_template] = React.useState(deck.use_card_template || false);
  const [card_template, set_card_template] = React.useState(deck.card_template || 'v1');

  const { is_api_settings_on } = store.getState().appBasicControls.organization_settings;
  React.useEffect(() => {
    client.query({
      query: apiQuery(),
      fetchPolicy: 'network-only',
      variables: {
        data: {
          model: "api_paths",
          query: `{\"where\": {\"organization_id\": \"${organization_id}\"}}`
        }
      }
    }).then((result) => {
      let response = result.data.cxl_internal_common_query;
      if (response.status) {
        setPaths(JSON.parse(response.data));
      }
    });
  }, []);


  const colorOptions = (
    store.getState().appBasicControls.organization.color_palette || []
  ).map((el, i) => {
    return {
      color: el,
      id: (
        <div
          style={{
            backgroundColor: `${el}`,
            width: '50px',
            height: '20px',
            borderRadius: '4px',
          }}
        ></div>
      ),
      index: i,
    };
  });

  if (isOpen === false) onClose();

  const getCoverImageUrlBasedOnCoverType = () => {
    if (cover_type == 'photo') {
      return preivew_image || getSrc(cover_image_data);
    };
    return icon_name;
  }

  const [
    updateDeck,
    { loading: onUpdateLoader, data: updatedDeck },
  ] = useMutation(updateDeckMutation);
  const [
    updateQuizDeck,
    { loading: onQuizUpdateLoader, data: updatedQuizDeck },
  ] = useMutation(updateQuizDeckMutation);
  const id = deck.id;

  const previewImage = file => {
    setPreview(URL.createObjectURL(file));
  };

  const onSave = () => {
    setIsLoading(true);
    let variables = {
      id,
      title,
      description,
      full_bleed,
      home_featured,
      organization_color_palette_index,
      cover_image_data,
      cover_image,
      cover_type,
      icon_name,
      has_data_source_connected,
      api_path_id,
      card_style: cardStyle,
      use_card_template,
      card_template
    }
    if (isQuizType) {
      variables['show_answer_validation'] = show_answer_validation
    }
    let dataToSave = {
      variables
    };
    if (isQuizType) return updateQuizDeck(dataToSave);
    updateDeck(dataToSave);
  };

  React.useEffect(() => {
    setIsLoading(false);
    (updatedDeck || updatedQuizDeck) && setIsOpen(false);
  }, [updatedDeck, updatedQuizDeck]);

  const outerGridOverride = {
    marginTop: '30px',
    marginLeft: '0px !important',
    marginRight: '0px !important',
    paddingLeft: '0px !important',
    paddingRight: '0px !important',
  };

  const getBackgroundPositionsAccordingToBleed = () => {

    console.log("full_bleed", full_bleed)
    var css = {};
    if (full_bleed) {
      css.backgroundSize = 'cover';
    } else {
      css.backgroundSize = 'contain';
    };
    return css;
  }

  const cardTemplateValue = React.useMemo(() => {
    return cardTemplateOptions.find(el => el.id == card_template)
  }, [card_template])

  return (
    <React.Fragment>
      <Modal
        overrides={{
          Root: {
            style: {
              zIndex: 3,
            }
          },
          Backdrop: {
            style: ({ $theme }) => ({
              backdropFilter: $theme.modalBackDropFilter,
            }),
          }
        }}
        isOpen={isOpen}
        onClose={() => {
          setIsOpen(false);
        }}
      >
        <ModalHeader>{''}</ModalHeader>
        <ModalBody style={{ flex: '1 1 0' }}>
          <Grid
            gridGaps={[12, 12, 24]}
            overrides={{
              Grid: {
                style: ({ $theme }) => outerGridOverride,
              },
            }}
          >
            <LabelMedium style={{ marginBottom: '16px' }}>General Information</LabelMedium>
            <Cell
              span={[1, 12]}
              overrides={{
                Cell: {
                  style: () => ({
                    paddingLeft: '0px !important',
                  }),
                },
              }}
            >
              <LabelMedium style={{ marginBottom: '16px' }}>Title</LabelMedium>
              <Input
                value={title}
                onChange={e => {
                  e.target.value.length <= titlesLength.deck && setTitle(e.target.value)
                }}
                placeholder="Your title"
                clearOnEscape
              />
              <ParagraphSmall style={{ marginBottom: '0px' }}>{title.length}/{titlesLength.deck}</ParagraphSmall>
              {title && title.length > titlesLength.deck && <p style={{ marginTop: '0.5rem', color: 'red' }}>Max {titlesLength.deck} characters allowed</p>}
            </Cell>
            <Cell
              span={[1, 12]}
              overrides={{
                Cell: {
                  style: () => ({
                    paddingLeft: '0px !important',
                  }),
                },
              }}
            >
              <LabelMedium style={{ marginBottom: '16px' }}>Description</LabelMedium>
              <Input
                value={description}
                onChange={e => setDescription(e.target.value)}
                placeholder="Your description"
                clearOnEscape
              />
            </Cell>
            <hr style={{ height: '2px', margin: 0, marginBottom: '24px', background: '#E2E2E2', width: '100%' }} />
            <LabelMedium style={{ marginBottom: '16px' }}>Appearance</LabelMedium>
            <Cell
              span={[1, 12]}
              overrides={{
                Cell: {
                  style: () => ({
                    paddingLeft: '0px !important',
                  }),
                },
              }}
            >
              <LabelMedium style={{ marginBottom: '16px' }}>Deck Color</LabelMedium>
              <Select
                options={colorOptions}
                labelKey="id"
                valueKey="color"
                onChange={({ value }) => {
                  if (value.length == 0) return;
                  return setColorIndex(value[0].index);
                }}
                value={[colorOptions[organization_color_palette_index]]}
                overrides={{
                  Popover: {
                    props: {
                      overrides: {
                        Body: { style: { zIndex: 3 } },
                      },
                    },
                  },
                }}
              />
            </Cell>
            <Cell
              span={[1, 12]}
              overrides={{
                Cell: {
                  style: () => ({
                    paddingLeft: '0px !important',
                  }),
                },
              }}
            >
              <LabelMedium style={{ marginBottom: '16px' }}>Card Style</LabelMedium>
              <Select
                options={cardStyleOptions}
                labelKey="label"
                valueKey="id"
                onChange={({ value }) => setCardStyle(value[0].id)}
                value={cardStyleOptions.find(el => el.id === cardStyle)}
                placeholder={cardStyle ? '' : 'Select...'}
                overrides={{
                  Popover: {
                    props: {
                      overrides: {
                        Body: { style: { zIndex: 3 } },
                      },
                    },
                  },
                }}
              />
            </Cell>
            <Cell
              span={[1, 12]}
              overrides={{
                Cell: {
                  style: () => ({
                    paddingLeft: '0px !important',
                  }),
                },
              }}
            >
              <div style={{ display: 'flex', marginTop: '15px', marginBottom: '10px' }}>
                <Checkbox
                  checked={use_card_template}
                  onChange={e => set_use_card_template(!use_card_template)}
                  labelPlacement={LABEL_PLACEMENT.right}
                >
                  <LabelMedium style={{ marginTop: '2px', marginBottom: '0px' }}>Card Template Enabled</LabelMedium>
                </Checkbox>
              </div>
            </Cell>
            {
              use_card_template && (
                <Cell
                  span={[1, 12]}
                  overrides={{
                    Cell: {
                      style: () => ({
                        paddingLeft: '0px !important',
                      }),
                    },
                  }}
                >
                  <LabelMedium style={{ marginBottom: '16px' }}>Card Template</LabelMedium>
                  <Select
                    options={cardTemplateOptions}
                    labelKey="title"
                    valueKey="id"
                    onChange={({ value }) => {
                      console.log({ value })
                      if (value.length == 0) return;
                      return set_card_template(value[0].id);
                    }}
                    value={cardTemplateValue}
                    overrides={{
                      Popover: {
                        props: {
                          overrides: {
                            Body: { style: { zIndex: 3 } },
                          },
                        },
                      },
                    }}
                  />

                </Cell>
              )
            }

            <Cell
              span={[1, 12]}
              overrides={{
                Cell: {
                  style: () => ({
                    paddingLeft: '0px !important',
                  }),
                },
              }}
            >
              <ImageUploadCXL
                title={'Deck Cover'}
                titleHelpText={'Select the cover image for deck'}
                previewUrls={getCoverImageUrlBasedOnCoverType()}
                downloadUrls={[sourceByType(cover_image_data, 'full_url')]}
                isMultiple={false}
                onDrop={(acceptedFiles, rejectedFiles) => {
                  setUpload(acceptedFiles[0]);
                  previewImage(acceptedFiles[0]);
                }}
                onImageDelete={() => {
                  setImage(null);
                  setUpload(null);
                  setPreview(null);
                }}
                showFullBleedSettings={true}
                fullBleed={full_bleed}
                onBleedChange={(value) => setFullBleed(value)}
                imageSize={deckCoverGuideline.size}
                imageSizeGuidelineURL={deckCoverGuideline.url}

                hasCoverTypeSelection={true}
                coverType={cover_type}
                onCoverTypeChange={(value) => set_cover_type(value)}
                iconName={icon_name}
                onIconChange={(value) => set_icon_name(value)}
              />
              {/* <div
                style={{
                  background: `linear-gradient(180deg, rgba(255, 255, 255, 0.44) 0%, rgba(0, 0, 0, 0.88) 100%), url(${getCoverImageUrlBasedOnCoverType()}), ${store.getState().appBasicControls.organization['color_palette'][organization_color_palette_index]}`,
                  border: '0px solid #7A5AF8',
                  marginTop: '8px',
                  marginBottom: '8px',
                  ...getBackgroundPositionsAccordingToBleed()
                }}
                className={'card-new-gradient-style'}

              >
              {cover_type == 'icon' && icon_name && <RenderIcon iconName={icon_name}></RenderIcon>}
              </div> */}
              {/* {cover_type == 'photo' && <FileUploader
                onDrop={(acceptedFiles, rejectedFiles) => {
                  setUpload(acceptedFiles[0]);
                  previewImage(acceptedFiles[0]);
                }}
              ></FileUploader>}
              {cover_type == 'icon' && <div style={{
                width: '100%',
                position: 'relative',
                float: 'left'
              }}><SelectIcon value={icon_name} onChange={(value) => {
                set_icon_name(value);
              }}></SelectIcon></div>} */}
            </Cell>
            {(is_api_settings_on || isQuizType) && id && <Cell
              span={[1, 12]}
              overrides={{
                Cell: {
                  style: () => ({
                    paddingLeft: '0px !important',
                  }),
                },
              }}
            >
              <LabelMedium style={{ marginBottom: '16px' }}>Advanced Settings</LabelMedium>
              <div style={{ display: 'flex' }}>

                {is_api_settings_on && <div style={{ marginLeft: '0px' }}>
                  <Checkbox
                    checked={has_data_source_connected}
                    onChange={e => set_has_data_source_connected(!has_data_source_connected)}
                    labelPlacement={LABEL_PLACEMENT.right}
                  >
                    <ParagraphSmall style={{ marginTop: '2px', marginBottom: '0px' }}>Has Data Source</ParagraphSmall>
                  </Checkbox>
                </div>}
                {
                  isQuizType && (
                    <div style={{ marginLeft: '16px' }}>
                      <Checkbox
                        checked={show_answer_validation}
                        onChange={e => set_show_answer_validation(!show_answer_validation)}
                        labelPlacement={LABEL_PLACEMENT.right}
                      >
                        <ParagraphSmall style={{ marginTop: '2px', marginBottom: '0px' }}>Show Answer Validation</ParagraphSmall>
                      </Checkbox>
                    </div>
                  )
                }

              </div>
            </Cell>}
            {is_api_settings_on && has_data_source_connected && <Cell
              span={[1, 12]}
              overrides={{
                Cell: {
                  style: () => ({
                    paddingLeft: '0px !important',
                  }),
                },
              }}
            >
              <LabelMedium style={{ marginBottom: '16px', marginBottom: '8px', }}>Api Path</LabelMedium>
              <Select
                options={paths}
                value={paths.filter(el => el.id == api_path_id)}
                labelKey="name"
                valueKey="id"
                placeholder=''
                onChange={({ option }) => {
                  if (option)
                    set_api_path_id(option.id);
                  else
                    set_api_path_id(null);
                }}
                overrides={{
                  Popover: {
                    props: {
                      overrides: {
                        Body: { style: { zIndex: 3 } },
                      },
                    },
                  },
                }}
              />
              <LabelMedium style={{ marginTop: '8px', cursor: 'pointer' }}>
                <a onClick={() => {
                  setIsMappingOpen(true);
                }}>Map Deck data with Api &#8594;</a>
              </LabelMedium>
            </Cell>}

          </Grid>
        </ModalBody>
        <ModalFooter>
          <ModalButton
            isLoading={isLoading}
            disabled={!title || title === '' || title.length > titlesLength.deck}
            onClick={() => {
              onSave();
            }}
          >
            Save
          </ModalButton>
        </ModalFooter>
      </Modal>
      <Modal
        isOpen={id && isMappingOpen}
        onClose={() => {
          setIsMappingOpen(false);
        }}
        overrides={{
          Backdrop: {
            style: ({ $theme }) => ({
              backdropFilter: $theme.modalBackDropFilter,
            }),
          },
          Dialog: {
            style: {
              width: '80vw',
              height: '90vh',
              display: 'flex',
              flexDirection: 'column',
            },
          },
          Root: {
            style: {
              zIndex: 4,
            }
          },
        }}
      >
        <div style={{
          paddingTop: '32px',
          paddingBottom: '32px'
        }}>
          <DeckApiMapping
            deck_id={id}
            organization_id={organization_id}
            paths={paths}
            api_path_id={api_path_id}
          />
        </div>
      </Modal>
    </React.Fragment>
  );
};
