import React, { Component, PureComponent, Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import { connect } from 'react-redux';
import { useStyletron } from 'baseui';
import { Button, SIZE } from 'baseui/button';
import FileOrLibraryPicker from './cardBackEditorComponents/fileOrLibraryPicker';
import { LabelSmall, LabelXSmall } from 'baseui/typography';
import { toaster } from 'baseui/toast';
import { Block } from 'baseui/block';
import { ProgressBar } from 'baseui/progress-bar';
import ConnectToData from './cardBackEditorComponents/data-connection';
import FormInputList from '../modules/generic_modules/formInputList'
import CardBackLoader from './CardBackComponentLoader';
import { setCardBackData } from '../../redux/actions/appBasicControls';

import modules from '../modules';

let portal;
if (typeof window !== `undefined`) {
  portal = document.createElement('div');
  portal.classList.add('my-super-cool-portal');

  if (!document.body) {
    throw new Error('body not ready for portal creation!');
  }

  document.body.appendChild(portal);
}

const svgIconsCss = {
  position: 'relative',
  float: 'right',
  marginRight: '18px',
  cursor: 'pointer',
};

let toastKey;

class PortalAwareItem extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showHoverContent: false,
      isCollapsed: false,
      isLoading: false,
    }
  };

  setShowHoverContent = (status) => {
    this.setState({
      showHoverContent: status
    })
  }
  render() {
    const {
      dragProvided,
      card,
      theme,
      snapshot,
      css,
      dispatch,
      getValueFromRedux,
      index,
      methods,
      lastIndex,
      isShowHoverContent = false,
      hoverContent = null,
      isLoading,
      isQuizType,
      jwPlayerData,
      libraries,
      isCommunication,
      hasDataSource,
      dataPoints,
      setCardTitleValueFromRedux,
      organization
    } = this.props;

    const { showHoverContent } = this.state;
    const usePortal = snapshot.isDragging;
    const down = () => {
      if (index != lastIndex) {
        methods.reOrderList('options2', index, index + 1);
      }
    };

    const up = () => {
      if (index != 0) {
        methods.reOrderList('options2', index, index - 1);
      }
    };

    const remove = () => {
      methods.removeFromEditor(index);
    };

    const clone = () => {
      methods.addNewToTheList(null, index + 1, card);
    };



    const undo = () => {
      methods.undoDeleted(index);
    };

    const onDelete = () => {
      let toastKey
      setTimeout(() => {
        if (this.state.isLoading || this.props.isLoading) {
          const msg = 'Deleting the card module data...';
          let t = setTimeout(() => {
            toastKey && toaster.clear(toastKey);
            this.setState({ isLoading: false })
          }, 2000);
          toastKey = toaster.negative(
            <>
              {msg}
            </>,
            {
              onClose: () => console.log('Toast closed.'),
              overrides: {
                InnerContainer: {
                  style: { width: '100%' },
                },
              },
            }
          );
          return;
        }
        this.setState({ isLoading: true })

        remove();
      }, 1500)
      setTimeout(() => {
        const msg = 'Block has been removed successfully. click below button to undo.';
        let t = setTimeout(() => {
          toastKey && toaster.clear(toastKey);
          this.setState({ isLoading: false })
        }, 1000);
        toastKey = toaster.warning(
          <>
            {msg}
            <Block
              marginTop="16px"
              display="flex"
              justifyContent="center"
            >
              <Button
                size={SIZE.compact}
                onClick={() => {
                  toaster.clear(toastKey);
                  clearTimeout(t);
                  undo();
                }}
              >
                Undo
              </Button>
            </Block>
          </>,
          {
            onClose: () => console.log('Toast closed.'),
            overrides: {
              InnerContainer: {
                style: { width: '100%' },
              },
            },
          }
        );
      }, 2000);
    }

    const { isCollapsed } = this.state;

    const child = (
      <div
        {...dragProvided.draggableProps}
        ref={dragProvided.innerRef}
        className={css({
          marginBottom: theme.sizing.scale900,
        })}
      >
        <div
          // {...dragProvided.dragHandleProps}
          className={css({
            position: 'relative',
            paddingTop: theme.sizing.scale500,
            paddingBottom: theme.sizing.scale500,
            height: '50px',
            borderRadius: '2px',
            ...isCollapsed ? {
              boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
              background: theme.colors.primaryB
            } : {}
          })}
        // onClick={() => this.setState({
        //   isCollapsed: !isCollapsed
        // })}
        >

          <div style={{ display: 'flex' }}>

            {/* <LabelLarge style={{
              marginRight: '8px',
              transition: '0.1s',
              ...!isCollapsed ? { width: '0px', overflow: 'hidden' } : {paddingLeft: '16px',}
            }}
            >
              {card.display}
            </LabelLarge> */}

            <LabelXSmall className={css({
              lineHeight: '28px',
              fontFamily: 'IBM Plex Sans',
              fontStyle: 'normal',
              fontWeight: '500',
              fontSize: '18px',
              lineHeight: '28px',
              color: '#1E1F2C'
            })}> {`Block ${index + 1}`}</LabelXSmall>


          </div>

          {/* donw */}
          <div style={{
            position: 'absolute',
            right: 0,
            top: '50%',
            transform: 'translateY(-50%)'
          }}>



            <svg
              width="16"
              height="10"
              viewBox="0 0 16 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className={css({ ...svgIconsCss, marginTop: '6px' })}
              onClick={down}
            >
              <path
                d="M14.12 7.8932e-07L8 6.18084L1.88 -2.80735e-07L7.07877e-07 1.90283L8 10L16 1.90283L14.12 7.8932e-07Z"
                fill={theme.colors.primary600}
              />
            </svg>
            {/* up */}
            <svg
              width="18"
              height="11"
              viewBox="0 0 18 11"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className={css({ ...svgIconsCss, marginTop: '6px' })}
              onClick={up}
            >
              <path
                d="M2.115 11L9 4.20108L15.885 11L18 8.90688L9 0L0 8.90688L2.115 11Z"
                fill={theme.colors.primary600}
              />
            </svg>
            {/* delete */}
            {<svg
              width="14"
              height="18"
              viewBox="0 0 14 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className={css(svgIconsCss)}
              onClick={() => {
                onDelete();
              }}
            >
              <path
                d="M1 16C1 17.1 1.9 18 3 18H11C12.1 18 13 17.1 13 16V4H1V16ZM14 1H10.5L9.5 0H4.5L3.5 1H0V3H14V1Z"
                fill={theme.colors.primary600}
              />
            </svg>}
            {/* clone */}
            {/* <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" className={css(svgIconsCss)}
           onClick={clone}
          >
            <path d="M2 4H0V18C0 19.1 0.9 20 2 20H16V18H2V4ZM18 0H6C4.9 0 4 0.9 4 2V14C4 15.1 4.9 16 6 16H18C19.1 16 20 15.1 20 14V2C20 0.9 19.1 0 18 0ZM17 9H13V13H11V9H7V7H11V3H13V7H17V9Z" fill={theme.colors.primaryA}/>
          </svg> */}
            {/* bookmark */}
            <svg
              width="14"
              height="18"
              viewBox="0 0 14 18"
              fill="none"
              xmlns="htt
              p://www.w3.org/2000/svg"
              className={css(svgIconsCss)}
            // onClick={() => this.setState({
            //   isCollapsed: !isCollapsed
            // })}
            >
              <path
                d="M12 0H2C0.9 0 0.0100002 0.9 0.0100002 2L0 18L7 15L14 18V2C14 0.9 13.1 0 12 0Z"
                fill={theme.colors.primary600}
              />
            </svg>

            {/* data source connection */}
          </div>
        </div>

        <div
          style={{
            background: theme.colors.primaryB,
            marginBottom: theme.sizing.scale400,
            border: theme.borders.border400,
            boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
            transition: '0.1s',
            borderRadius: '2px',
            ...isCollapsed ? { height: '0px', overflow: 'hidden' } : { padding: '1rem', },
          }}
        >
          <h4
            {...dragProvided.dragHandleProps}
            className={css({
              color: theme.colors.primaryA,
              fontFamily: 'IBM Plex Sans',
              fontStyle: 'normal',
              fontWeight: '600',
              fontSize: '14px',
              lineHeight: '28px'
            })}
          >
            {card.display}{' '}
          </h4>
          {card.inputs.map((el, i) => {
            /**
             * element is required to pass in every component
             */
            const dataToPass = {
              root: card,
              position: i,
              key: el.key,
              value: getValueFromRedux(card.dId, i, el.value) || el.value,
              // [el.key]: getValueFromRedux(card.dId, i, el.value) || el.value,
              placeholder: el.placeholder,
              title: el.title,
              dispatch: dispatch,
              theme: theme,
              style: { marginBottom: theme.sizing.scale400 },
              options: el.options,
              pathToStoreValue: el.pathToStoreValue,
              loadOptionsFromState: el.loadOptionsFromState,
              labelKey: el.labelKey,
              multiple: el.multiple,
              element_type: el.element_type,
              accept: el.accept,
              onUpdate: methods.onUpdate,
              typeToPick: el.typeToPick,
              css,
              maxLength: el.maxLength,
              alwaysShowDropZone: el.alwaysShowDropZone,
              isCommunication,
              hasDataSource,
              dataPoints,
              ...(el.type == "Input" && el?.disable && { disable: true }),
              organization,
              organization_id: organization.id,
              jwPlayerData,
              extraStateKeyToUpdate: el.extraStateKeyToUpdate
            };


            let Component = <></>;

            if (el.type === 'FilePicker') {
              dataToPass.showHint = el.showHint;
            }
            switch (el.type) {
              case 'FileOrLibraryPicker':
                Component = <FileOrLibraryPicker {...dataToPass} libraries={libraries}></FileOrLibraryPicker>
                break;
              default:
                if (modules?.[el.type]?.['builder']) {
                  Component = modules?.[el.type]?.['builder']({ ...dataToPass });
                }

            };
            const ComponentWitLoader = <CardBackLoader {...dataToPass}>
              <Suspense fallback={<div>... loading</div>}>
                {Component}
              </Suspense>
            </CardBackLoader>;
            if (hasDataSource)
              return <ConnectToData {...dataToPass}>
                {ComponentWitLoader}
              </ConnectToData>;
            return ComponentWitLoader
          })}
        </div>
        {isShowHoverContent ? <>
          {lastIndex == index ? <div>
            <div
              className={css({
                paddingTop: theme.sizing.scale500,
                // paddingBottom: theme.sizing.scale500,
                height: '60px',
              })}
              onMouseEnter={() => this.setShowHoverContent(true)}
              onMouseLeave={() => this.setShowHoverContent(false)}
            >{hoverContent(index)}</div>
          </div> :
            <div
              className={css({
                paddingTop: theme.sizing.scale500,
                // paddingBottom: theme.sizing.scale500,
                height: '60px',
              })}
              onMouseEnter={() => this.setShowHoverContent(true)}
              onMouseLeave={() => this.setShowHoverContent(false)}
            >
              {showHoverContent && <div>
                {hoverContent(index)}
              </div>}
            </div>
          }
        </> : ''}
      </div>
    );
    if (!usePortal) {
      return child;
    }
    return ReactDOM.createPortal(child, portal);
  }
}

const Editor = ({
  data,
  cardBackData,
  methods,
  dispatch,
  hideDropArea = false,
  isShowHoverContent = false,
  hoverContent = null,
  minHeight = "60vh",
  isModal = false,
  isMainPageModal,
  isLoading,
  jwPlayerData,
  libraries,
  isQuizType,
  isCommunication,
  hasDataSource,
  dataPoints,
  cardTitle,
  organization
}) => {
  const [css, theme] = useStyletron();
  const [showHoverContent, setShowHoverContent] = React.useState(false);
  const getValueFromRedux = (cardId, inputIndex, oValue) => {
    const cardBackDataProps = cardBackData.slice();
    if (!cardBackDataProps || cardBackDataProps === undefined) return '';
    let indexInState = cardBackDataProps.findIndex(el => el.dId == cardId);
    if (indexInState == -1) return '';
    let stateValue = cardBackDataProps[indexInState].inputs[inputIndex].value;
    return oValue || stateValue;
  };

  const setCardTitleValueFromRedux = (cardId, inputIndex, oValue, key) => {
    const cardBackDataProps = cardBackData.slice();
    if (!cardBackDataProps || cardBackDataProps === undefined) return '';
    let indexInState = cardBackDataProps.findIndex(el => el.dId == cardId);
    if (indexInState == -1) return '';
    let stateValue = cardBackDataProps[indexInState].inputs[inputIndex].value;

    if (key == "tracking_name" && oValue === undefined && stateValue === undefined) {
      cardBackDataProps[indexInState].inputs[inputIndex].value = cardTitle
      dispatch(setCardBackData(cardBackData));

    }
  };


  // for now if options has web card module then we need to hide this purple drop area
  const isDropAreaValidAccordingToOptions = () => {
    let isValid = true;
    for (var i = 0; i < data.options.length; i++) {
      if (data.options[i].__typename == "WebCardModule") {
        isValid = false;
        break;
      };
      if (data.options[i].parent == "add_communication") {
        isValid = false;
        break;
      }
    };
    return isValid;
  };

  return (
    // <ToasterContainer placement={PLACEMENT.bottomRight}>
    <Droppable
      droppableId={data.droppableId}
      type={data.type}
      direction="vertical"
      isCombineEnabled={false}
    >
      {dropProvided => (
        <div {...dropProvided.droppableProps}>
          <div>
            <div>
              <div
                style={{
                  display: 'block',
                  minHeight: minHeight,
                  padding: '24px',
                  ...isModal ? { maxHeight: isMainPageModal ? 'calc(100vh - 72px)' : 'calc(100vh - 231px)', overflow: 'scroll' } : {}
                }}
                ref={dropProvided.innerRef}
              >
                {[...data.options]
                  .filter(el => !el.isDeleted)
                  .sort((a, b) => a.position - b.position)
                  .map((card, index) => (
                    <Draggable
                      key={card[data.idKey]}
                      draggableId={card[data.idKey]}
                      index={index}
                      disableInteractiveElementBlocking={false}
                    >
                      {(dragProvided, snapshot) => (
                        <>
                          {index != 0 && <hr style={{ height: '2px', margin: 0, marginTop: '16px', marginBottom: '0px', background: '#E2E2E2' }} />}
                          <PortalAwareItem
                            dragProvided={dragProvided}
                            jwPlayerData={jwPlayerData}
                            libraries={libraries}
                            snapshot={snapshot}
                            theme={theme}
                            css={css}
                            card={card}
                            getValueFromRedux={getValueFromRedux}
                            setCardTitleValueFromRedux={setCardTitleValueFromRedux}
                            dispatch={dispatch}
                            index={index}
                            lastIndex={data.options.filter(el => !el.isDeleted).length - 1}
                            methods={methods}
                            isShowHoverContent={isShowHoverContent}
                            hoverContent={hoverContent}
                            showHoverContent={showHoverContent}
                            setShowHoverContent={setShowHoverContent}
                            isQuizType={isQuizType}
                            isLoading={isLoading}
                            isCommunication={isCommunication}
                            hasDataSource={hasDataSource}
                            dataPoints={dataPoints}
                            cardTitle={cardTitle}
                            organization={organization}
                          ></PortalAwareItem>
                        </>
                      )}
                    </Draggable>
                  ))}
                {/* {isDropAreaValidAccordingToOptions() && hideDropArea != true && <div
                  className={css({
                    border: '2px dashed #7A5AF8',
                    height: '136px',
                    background: '#EFF3FE',
                    display: 'flex',
                    alignItems: 'center'
                  })}
                >
                  <LabelSmall
                    style={{
                      width: '100%',
                      textAlign: 'center',
                      color: theme.colors.primaryC
                    }}
                  >Drag component from builder here</LabelSmall>
                </div>} */}
                {dropProvided.placeholder}
              </div>
            </div>
          </div>
        </div>
      )}
    </Droppable>
  );
};

const mapStateToProps = props => {
  let { cardBackData, isLoading, organization } = props.appBasicControls;
  return { cardBackData, isLoading, organization };
};
export default connect(mapStateToProps)(Editor);
