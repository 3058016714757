import { Button as BaseButton } from 'baseui/button';
import styled from '@emotion/styled';

const Button = styled(BaseButton)`
  background-color: ${p => (p.isSelected ? '#7A5AF8' : 'white')};
  color: ${p => (p.isSelected ? 'white' : 'black')};;
  padding-left: 20px;
  padding-right: 20px;
  width: 100%;
  height: 58px;
  line-height: 58px;
  margin-top: 16px;
  border-radius: 2px;
  font-weight: 500;
  font-size: 14px;
  justify-content: left;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
  margin-right: 16px;
`;

export default Button;
