import React from 'react';
import styled from '@emotion/styled';
import { Button, SHAPE } from 'baseui/button'
import { useStyletron } from 'baseui';
import PropTypes from 'prop-types';
import svgIcons from '../images';
import './index.css';

const AddAdditionalContainer = styled.div`
  width: 90%;
  max-width: 405px;
  margin-top: 25px;
  margin-bottom: 25px;
  margin-left: 8%;
  display: flex;
  justify-content: center;
`;

const CardBackFloatingListContainer = (props) => {
  const {
    onClickAddBtn,
    addBtnText,
  } = props;
  const [css, theme] = useStyletron();

  const renderAddVideoButton = React.useMemo(() => {
    const btnOverrides = {
      BaseButton: {
        style: {
          color: '#7A5AF8',
          maxWidth: '356px',
          width: '100%',
          border: '3px solid #4D4B45',
          boxSizing: 'border-box',
          boxShadow: '0px 8px 36px rgba(0, 0, 0, 0.17)',
          borderRadius: '20px',
          background: 'white',
          ':hover': {
            background: '#7A5AF8',
            color: 'white'
          }
        }
      }
    };

    const startEnhancer = () => (
      <img
        src={svgIcons.AddGradientIcon}
        className={css({
          width: '20px',
          height: '20px',
          marginBottom: '0'
        })}
      />
    )
    return (
      <AddAdditionalContainer>
        <Button
          onClick={onClickAddBtn}
          overrides={btnOverrides}
          startEnhancer={startEnhancer}
        >
          {addBtnText}
        </Button>
      </AddAdditionalContainer>
    )
  }, [props])
  return (
    <div className='card_back_float_container'>
      <div className='card_back_list_container'>
        {props.children}
        {renderAddVideoButton}
      </div>
    </div>
  )
}

CardBackFloatingListContainer.propTypes = {
  addBtnText: PropTypes.string,
  onClickAddBtn: PropTypes.func
}

CardBackFloatingListContainer.defaultProps = {
  addBtnText: '',
  onClickAddBtn: () => { }
}


export default CardBackFloatingListContainer;