import * as React from 'react';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalButton,
} from 'baseui/modal';
import { fetchCloudinaryFolders, fetchCloudinarySubFolders } from '../../util/index';
import {
  Checkbox,
  LABEL_PLACEMENT
} from "baseui/checkbox";
import { Button as BaseButton } from 'baseui/button';
import styled from '@emotion/styled';
import { ParagraphSmall } from 'baseui/typography';

const Button = styled(BaseButton)`
  background-color: #7A5AF8;
  padding-left: 20px;
  padding-right: 20px;
  height: 24px;
  line-height: 24px;
  margin-top: 0px;
  border-radius: 20px;
  font-size: 12px;
  margin-left: 12px;
`;


export default function (props) {
  const [selected, setSelected] = React.useState(props.folder);
  const [loadingSub, setLoadingSub] = React.useState(null);
  const [loadedSub, setLoadedSub] = React.useState([]);
  const [folders, setFolders] = React.useState([]);

  React.useEffect(() => {
    fetchCloudinaryFolders(props.organization_id)
      .then((d) => {
        setFolders(d)
      })
  }, []);

  const fetchSub = (folder, index_path, index) => {
    fetchCloudinarySubFolders(props.organization_id, encodeURIComponent(folder.path))
      .then((d) => {
        console.log(index_path, index);
        let f = [...folders];
        console.log(f);
        let f1 = f;
        index_path.forEach(el => {
          f1 = f1[el].sub;
        })
        console.log(f1);
        f1[index].sub = d;
        console.log(f);
        setFolders(f);
        let q = [...loadedSub];
        q.push(folder.path);
        setLoadedSub(q);
        setLoadingSub(null)
      })
  };

  console.log(folders, loadedSub, selected);


  const renderFolders = (f, index_path = []) => {
    return f.map((el, index) => {
      console.log(el.path);
      return <div style={{ paddingTop: '10px', paddingBottom: '10px' }}>
        <Checkbox
          checked={selected == el.path}
          onChange={e => setSelected(el.path)}
          labelPlacement={LABEL_PLACEMENT.right}
        >
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <div>{el.name}</div>
            {el.sub_folders_count > 0 && !loadedSub.includes(el.path) && <div style={{ marginBottom: '0px' }}>
              <Button
                isSelected onClick={() => {
                  fetchSub(el, index_path, index);
                  setLoadingSub(el.path);
                }}
                isLoading={el.path == loadingSub}
              >Load Sub Folders</Button>
            </div>}
          </div>
        </Checkbox>
        <div style={{ paddingLeft: '32px' }}>
          {el.sub && el.sub.length > 0 && renderFolders(el.sub, [...index_path, index])}
        </div>
      </div>
    })
  }

  return <Modal
    overrides={{
      Dialog: {
        style: {
          width: '70vw',
          display: 'flex',
          flexDirection: 'column',
        },
      },
      Backdrop: {
        style: ({ $theme }) => ({
          backdropFilter: $theme.modalBackDropFilter,
        }),
      },
      Root: {
        style: {
          zIndex: 3
        }
      }
    }}
    isOpen={props.isOpen}
    onClose={() => props.onClose(props.folder)}

  >
    <ModalHeader>Select Cloudinary Folder</ModalHeader>
    <ModalBody style={{ flex: '1 1 0' }}>
      <div style={{ padding: '8px', backgroundColor: '#7A5AF8', borderRadius: '4px', marginBottom: '14px' }}>
        <ParagraphSmall style={{ color: '#fff', marginBottom: '0px' }}>If you can not find your already selected folder checked here maybe it is inside any sub folder so you have to load sub folders so you can see that selected folder.</ParagraphSmall>
      </div>
      {renderFolders(folders)}
    </ModalBody>
    <ModalFooter>
      <ModalButton onClick={() => {
        props.onClose(selected);
      }}>
        Submit
      </ModalButton>
    </ModalFooter>
  </Modal>
};