import React, { useEffect, useMemo, useState } from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { useLazyQuery, useQuery } from '@apollo/client';
import { Skeleton } from 'baseui/skeleton';
import { LabelMedium, ParagraphSmall } from 'baseui/typography';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalButton,
  SIZE,
  ROLE,
} from 'baseui/modal';
import { Grid, Cell } from 'baseui/layout-grid';
import { Input } from 'baseui/input';
import { useMutation } from '@apollo/client';
import { Textarea } from 'baseui/textarea';
import { useStyletron } from 'baseui';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import { editorBtnOptions as CardBuilderOptions } from '../../../data/cardBuilderOptions';
import AddContentButton from '../addContentButton';
import Card from '../cards/card';
import { getSrc } from '../../util';
import { card_help } from '../help/helpTexts';
import HelpQuestionMark from '../help/questionMarkHelp';
import titlesLength from '../../../data/titlesLength.json';
import FormBuilderComponent, { FormBuilderHomeComponent } from '../builder/formBuilder';
import { archiveTemplateTagIntegration, createSharePointFormModuleTemplate, deleteFormTemplateByTag, publishTemplateTagIntegration, sharepoint_form_templates, template_tags_integration, template_tags_integration_group_by_tag, template_tags_org, updateSharePointFormModuleTemplate, updateTemplateTagIntegrationPositions } from './queries';
import { Select } from 'baseui/select';
import { FileUploader } from 'baseui/file-uploader';
import axios from 'axios';
import { client, getServerUrl } from '../../apollo/client';
import { cellPaddingOverrides } from '../overrides';
import { toaster } from 'baseui/toast';
import { MODE, StatefulButtonGroup } from 'baseui/button-group';
import Button from '../styledButton';
import PortalAwareItem from '../home-components/portal';
import { arrayMove } from 'baseui/dnd-list';
import UpdateTagModal from '../modals/update-template-tag-modal';
import { Checkbox, LABEL_PLACEMENT } from 'baseui/checkbox';
import Toggle from 'react-toggle';


const itemProps = {
  display: 'flex',
  justifyContent: 'flex-end',
  paddinBottom: '100px'
};

const outerGridOverride = {
  marginTop: '30px',
  marginLeft: '0px !important',
  marginRight: '0px !important',
  paddingLeft: '0px !important',
  paddingRight: '0px !important',
};

const selectOptions = [
  {
    name: 'All',
    key: 'all',
    dataQuery: {}
  },
  {
    name: 'Archived',
    key: 'archived',
    dataQuery: {}
  }
]

const currentComponentConfig = CardBuilderOptions.find((el) => el.__typename == "SharePointFormModule");

const buttonOverrides = {
  BaseButton: {
    style: () => ({
      fontFamily: 'Roboto'
    })
  }
}

const TAB_STATUS = {
  ALL: 'all',
  LIVE: 'published',
  ARCHIVE: 'archived'
};

const AddTemplateChildsModalWarning = ({ isOpen, onSave, onChangePublish, isLoading, onClose, isPublish }) => {
  const onClickYes = () => {
    onSave(true)
  }

  const onClickNo = () => {
    onSave(false)
  }

  const onChangePublishChild = (e) => {
    console.log('e.target.checked', e.target.checked)
    onChangePublish(e.target.checked);
  }

  return (
    <Modal
      overrides={{
        Root: {
          style: {
            zIndex: 3,
          }
        }
      }}
      onClose={onClose}
      autoFocus={false}
      isOpen={isOpen}
    // closeable={false}
    >
      <ModalHeader>Do you want to add this form to all imported channels</ModalHeader>
      <ModalBody>
        <div
          style={{
            display: 'flex',
            marginTop: '10px'
          }}
        >
          <div className={'user-toggle-container'} style={{ display: 'flex', flexDirection: 'row', alignContent: 'center', padding: '0.3rem' }}>
            <Toggle
              defaultChecked={isPublish}
              checked={isPublish}
              icons={false}
              onChange={onChangePublishChild}
            />
            <p style={{ color: 'black', fontSize: '16px', fontWeight: 500, margin: 0, marginLeft: '0.7rem' }}>
              Publish all imported forms?
            </p>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <ModalButton
          onClick={onClickYes}
          isLoading={isLoading}
        >
          Yes
        </ModalButton>
        <ModalButton
          onClick={onClickNo}
          isLoading={isLoading}
        >
          No
        </ModalButton>
      </ModalFooter>
    </Modal>
  )
}



const SharepointFormTemplates = ({
  organization_id,
  defaultTheme,
  ...props }) => {

  const [css, theme] = useStyletron();

  const [isDeleteFormModalOpen, setIsDeleteFormModalOpen] = useState(false);
  const [formCreationTag, setFormCreationTag] = React.useState();
  const [selectedTemplateTagForm, setSelectedTemplateTagForm] = React.useState();
  const [selectedModule, setSelectedModule] = React.useState();
  const [cardTitle, setCardTitle] = useState('');
  const [cardSubTitle, setCardSubTitle] = useState('');
  const [selectedTag, setSelectedTag] = React.useState();
  const [selectedEditTag, setSelectedEditTag] = React.useState();
  const [tags, setTags] = useState([]);
  const [templateTagIntegrationData, setTemplateTagIntegrationData] = useState([]);
  const [deletedFormList, setDeletedFormList] = useState([]);

  const [showEditModal, setShowEditModal] = React.useState(false);
  const [isFormModalOpen, setIsFormModalOpen] = useState(false);
  const [isImportModalOpen, setIsImportModalOpen] = useState(false);
  const [isAdvanceSettingOpen, setIsAdvanceSettingOpen] = useState(false);
  const [isDefaultValuseSetUploaderOpen, setIsDefaultValuseSetUploaderOpen] = useState(false);
  const [isTagEditModalOpen, setIsTagEditModalOpen] = useState(false);
  const [isAddWarningModalOpen, setIsAddWarningModalOpen] = useState(false);
  const [isDeletWarningModalOpen, setIsDeletWarningModalOpen] = useState(false);
  const [isArchiveWarningModalOpen, setIsArchiveWarningModalOpen] = useState(false);
  const [isUnArchiveWarningModalOpen, setIsUnArchiveWarningModalOpen] = useState(false);
  const [publish_child_components, setPublishChildComponents] = useState(false);

  const [importFile, setImportFile] = React.useState(null);
  const [importExcelFile, setImportExcelFile] = React.useState(null);
  const [importExcelType, setImportExcelType] = React.useState(null);
  const [importLoading, setImportLoading] = React.useState(false);
  const [tabFilter, setTabFilter] = useState('all');

  const [isFormCreating, setIsFormCreating] = useState(false);
  const [isFormDeleteLoading, setIsFormDeleteLoading] = useState(false);
  const [isFormArchiveLoading, setIsFormArchiveLoading] = useState(false);

  const [formsPublishingList, setFormPublishingList] = useState([]);

  const [createForm, { loading: createFormLoading, data: createdForm }] = useMutation(
    createSharePointFormModuleTemplate
  );

  const [fetchFormTemplates, { data: formTemplates, loading: formTemplatesLoading, refetch: refetchFormTemplates }] = useLazyQuery(sharepoint_form_templates,
    {
      variables: {
        organization_id
      },
      fetchPolicy: 'network-only'
    }
  );

  const [fetchTemplateTagIntegration, { data: templateTagIntegration, loading: templateTagIntegrationLoading, refetch: refetchTemplateTagInteg }] = useLazyQuery(template_tags_integration_group_by_tag,
    {
      variables: {
        organization_id
      },
      fetchPolicy: 'network-only'
    }
  );

  const [deleteFormTemplate, { loading: isFormDeleting, }] = useMutation(deleteFormTemplateByTag);
  const [archiveFormTemplate, { loading: isFormArchiving, }] = useMutation(archiveTemplateTagIntegration);
  const [updateFormTemplatePosition, { loading: isFormTemplatePosition, }] = useMutation(updateTemplateTagIntegrationPositions);
  const [publishFormTemplate, { loading: isFormPublishing, }] = useMutation(publishTemplateTagIntegration);

  useEffect(() => {
    fetchAllTemplates()
    loadTags();
  }, []);

  useEffect(() => {
    const {
      template_tags_integration_group_by_tag: templateTagIntegrations = []
    } = templateTagIntegration || {};

    setTemplateTagIntegrationData(templateTagIntegrations || []);
  }, [templateTagIntegration])

  const fetchAllTemplates = async () => {
    return await Promise.all([
      fetchTemplateTagIntegration(),
      fetchFormTemplates()
    ])
  }

  const showToaster = (msg, method = 'info') => {
    let toastKey = toaster[method](
      <>
        {msg}
      </>,
      {
        overrides: {
          InnerContainer: {
            style: { width: "100%" }
          }
        }
      }
    );
    setTimeout(() => { toaster.clear(toastKey) }, 3000);
  };

  const loadTags = () => {
    client.query({
      query: template_tags_org(organization_id),
      fetchPolicy: 'network-only'
    }).then(({ data }) => {

      setTags([...data.template_tags_org].sort((a, b) => a.position - b.position));
    })
  };

  /////////////////////////////

  const onFormDeleteClick = async (delete_child_forms = false) => {

    if (!selectedTemplateTagForm) {
      return;
    }
    const {
      tag_id,
      form_id
    } = selectedTemplateTagForm || {}
    console.log('item', tag_id, form_id);
    setIsFormDeleteLoading(true)
    const { data } = await deleteFormTemplate({
      variables: {
        tag_id,
        form_id,
        delete_child_forms
      }
    })
    console.log('deletedForm', data);
    if (data?.deleteFormTemplateByTag) {
      setDeletedFormList([
        ...deletedFormList,
        form_id
      ])
      showToaster('Succefully Deleted', 'positive');
      await fetchAllTemplates();
      setDeletedFormList([]);
      setIsFormDeleteLoading(false);
      setIsDeleteFormModalOpen(false);
      setIsDeletWarningModalOpen(false);
      setSelectedTemplateTagForm();
    } else {
      showToaster('Something went Wrong', 'negative');
      setIsFormDeleteLoading(false);
    }

  }

  const onCloseTemplateTagModel = () => {
    setSelectedTemplateTagForm();
    setIsDeleteFormModalOpen(false)
  }

  const onCloseFormModal = () => {
    setIsFormModalOpen(false);
    setCardTitle('');
    setCardSubTitle('');
    setFormCreationTag();
  }

  const onSaveFormTemplate = async (add_form_child_components = false) => {
    try {
      let payload = {
        "title": cardTitle,
        "description": cardSubTitle,
        "dialog_open_button_text": "",
        "form_type": "single_form",
        organization_id,
        "position": 0,
        "schemaArr": [],
        "show_on_home_screen": true,
        add_form_child_components,
        publish_child_components
      }

      if (formCreationTag && formCreationTag.length > 0) {
        debugger
        payload.tag_ids = formCreationTag.map(el => el.id)
        let tagTemplates = templateTagIntegrationData && templateTagIntegrationData.find(el => el.tag_id === formCreationTag[0].id);
        payload.position = tagTemplates && tagTemplates?.templates.length || 0
      }

      setIsFormCreating(true);

      await createForm({
        variables: payload
      })

      await fetchAllTemplates();
      setIsFormCreating(false);
      onCloseFormModal();
      setIsAddWarningModalOpen(false);
    } catch (err) {
      setIsFormCreating(false);
      showToaster(err.message, 'negative');
      console.log('onSaveFormTemplate error', err)
    }
  }

  const onCloseImportModal = () => {
    setIsImportModalOpen(false);
  }

  const onCloseAdvanceSettingModal = () => {
    setIsAdvanceSettingOpen(false);
  }

  const onCloseDefaultValuseSetModal = () => {
    setImportExcelFile();
    setIsDefaultValuseSetUploaderOpen(false);
    setImportExcelType('');
  }

  const onClickImportModal = () => {
    setIsImportModalOpen(true)
  }

  const onClickAdvanceModal = () => {
    setIsAdvanceSettingOpen(true)
  }

  const onClickImport = () => {
    setImportLoading(true);

    const formData = new FormData();
    formData.append('file', importFile);
    formData.append('organization_id', organization_id);

    let url = `${getServerUrl()}/sharepoint_forms_template_import`;

    if (!url) return;

    axios.post(url, formData)
      .then(async (result) => {
        console.log(result);
        setIsImportModalOpen(false);
        await fetchAllTemplates();
        loadTags();
        showToaster('Forms Imported Successfully', 'positive')
      }).catch((err) => {
        console.log('error import', err)
        showToaster(JSON.stringify(err), 'negative')
      }).finally(() => {
        setImportLoading(false);
      })
  }

  const onClickAddCard = () => {
    setIsFormModalOpen(true);
  }

  const onCardDragEnd = async ({ destination, source, templates, tagId }) => {
    try {
      if (!destination) {
        return;
      }
      let newArr = arrayMove(
        JSON.parse(JSON.stringify(templates)),
        source.index,
        destination.index
      );
      newArr = newArr.map((el, i) => {
        return ({
          ...el.template,
          index: i
        })
      });

      const updatedPositionPayload = newArr.map((el) => ({
        id: el.id,
        position: el.index,
      }))
      // console.log({ templateTagIntegrationData, templates, updatedPositionPayload });
      updateTagTemplatesPosition(tagId, updatedPositionPayload)
      showToaster('Updating form positions...')
      const { data, errors } = await updateFormTemplatePosition({
        variables: {
          input: updatedPositionPayload
        }
      })
      if (errors && errors.length > 0) {
        showToaster(JSON.stringify(errors), 'negative');
      }
      showToaster('Form template positions updated successfully', 'positive')
      fetchAllTemplates();
    } catch (err) {
      console.log('onCardDragEnd error', err);
      showToaster(err.message, 'negative')
    }
  }

  const updateTagTemplatesPosition = (tagId, updatedPositions) => {
    const templateTagIntegrationIdx = templateTagIntegrationData.findIndex(el => el.tag_id === tagId);
    if (templateTagIntegrationIdx !== undefined && templateTagIntegrationIdx !== null) {
      let updatedTemplateTagIntegData = templateTagIntegrationData && [...templateTagIntegrationData] || [];
      const {
        templates = []
      } = updatedTemplateTagIntegData[templateTagIntegrationIdx]
      let updatedTemplates = templates.map(templ => {
        const tempData = updatedPositions.find(el => el.id === templ.id)
        if (tempData) {
          return ({
            ...templ,
            position: tempData.position
          })
        }
        return templ
      })
      updatedTemplateTagIntegData[templateTagIntegrationIdx] = {
        ...updatedTemplateTagIntegData[templateTagIntegrationIdx],
        templates: updatedTemplates
      }
      setTemplateTagIntegrationData(updatedTemplateTagIntegData)
    }
  }

  const onOpenTagEditModal = (tag) => {
    setSelectedEditTag(tag);
    setIsTagEditModalOpen(true);
  }

  const onCloseTagEditModal = () => {
    setSelectedEditTag();
    setIsTagEditModalOpen(false);
  }

  const onUpdateTagEditModal = () => {
    console.log('Updated onUpdateTagEditModal')
    onCloseTagEditModal();
    fetchAllTemplates();
    loadTags();
  }

  const onCloseAddWarningModal = () => {
    setIsAddWarningModalOpen(false)
  }

  const onCloseDeleteWarningModal = () => {
    setIsDeletWarningModalOpen(false)
  }

  const onCloseArchiveWarningModal = () => {
    setIsArchiveWarningModalOpen(false)
  }

  const onCloseUnArchiveWarningModal = () => {
    setIsUnArchiveWarningModalOpen(false)
  }

  const onFromArchiveClick = async (is_archived = false, archive_child_forms = false) => {
    try {
      if (!selectedTemplateTagForm) {
        return;
      }
      showToaster(`Please wait while we are ${is_archived ? '' : 'Un'}archiving`);
      setIsFormArchiveLoading(true);
      const updatedFormTempl = await archiveFormTemplate({
        variables: {
          input: {
            id: selectedTemplateTagForm.id,
            is_archived,
            archive_child_forms
          }
        }
      })
      refetchTemplateTagInteg()
      if (updatedFormTempl.errors) {
        showToaster('Something went wrong!', 'negative');
        return;
      }
      setSelectedTemplateTagForm()
      setIsArchiveWarningModalOpen(false)
      setIsUnArchiveWarningModalOpen(false)
      setIsFormArchiveLoading(false);
      showToaster(`Form Template ${is_archived ? '' : 'Un'}archived`, 'positive');
    } catch (err) {
      console.log('onFromArchiveClick error', err);
      setIsFormArchiveLoading(false);
      showToaster((err || {}).toString(), 'positive');
    }
  }

  const onPublishFormClick = async (id, published = false) => {
    try {
      if (!id) {
        return;
      }

      setFormPublishingList(prev => ([
        ...prev,
        id
      ]))

      if (formsPublishingList.includes(id)) {
        showToaster(`Please wait while form  published status update is already in progress!`);
        return;
      }

      showToaster(`Please wait while we are ${published ? '' : 'Un'}publishing`);
      const updatedFormTempl = await publishFormTemplate({
        variables: {
          input: {
            id,
            published,
            organization_id
          }
        }
      })
      refetchTemplateTagInteg()
      if (updatedFormTempl.errors) {
        showToaster('Something went wrong!', 'negative');
        return;
      }
      setFormPublishingList(prev => {
        return (prev || []).filter(el => {
          if (el !== id) {
            return el
          }
        })
      })
      showToaster(`Form Template ${published ? '' : 'Un'}publishing`, 'positive');
    } catch (err) {
      console.log('onPublishFormClick error', err);
      setFormPublishingList(prev => {
        return (prev || []).filter(el => {
          if (el !== id) {
            return el
          }
        })
      })
      showToaster((err || {}).toString(), 'positive');
    }
  }

  const onExportFormDetails = () => {
    window.open(`${getServerUrl()}/export_reference_form_default_field_value/${organization_id}/form_type/template`, '_blank').focus()
  }

  const onImportFormDefaultValSet = () => {
    console.log(importExcelFile)
    const formData = new FormData();
    formData.append('file', importExcelFile);
    formData.append('import_type', importExcelType ? 'append' : 'replace')
    formData.append('organization_id', organization_id);

    let url = `${getServerUrl()}/import_reference_form_default_field_value`;

    if (!url) return;
    setImportLoading(true);
    axios.post(url, formData)
      .then(async (result) => {
        console.log(result);
        onCloseDefaultValuseSetModal();
        showToaster('Forms Default value set Imported Successfully', 'positive')
      }).catch((err) => {
        console.log('error import', err)
        showToaster(JSON.stringify(err), 'negative')
      }).finally(() => {
        setImportLoading(false);
      })
  }


  /////////////////////////////////////////////////////////////////// Render Functions ////////////////////////////////////////////////////////////////

  const renderFormCard = (item, index, component_type, tag, templateTagInt) => {
    if (!item) {
      return null;
    }
    let title = item.title;
    let imageSrc = getSrc(item.file_data || item.front_image_data);
    let card_id = item.id;
    let full_bleed = item.full_bleed;

    const onDeleteClick = async () => {
      // console.log('item', item, tag)
      setSelectedTemplateTagForm({
        tag_id: tag && tag.id,
        form_id: item.id
      })
      setIsDeleteFormModalOpen(true)
    }

    const onSettingsClick = () => {
      // console.log('onSettingsClick', { formTemplates, item})
      setSelectedModule({
        content_module: item
      })
      setShowEditModal(true)
    }

    const onArchiveClick = async () => {
      // console.log({ item, tag })
      setSelectedTemplateTagForm({
        id: templateTagInt.id,
        tag_id: tag && tag.id,
        form_id: item.id
      })
      setIsArchiveWarningModalOpen(true)
    }


    const onUnArchiveClick = async () => {
      setSelectedTemplateTagForm({
        id: templateTagInt.id,
        tag_id: tag && tag.id,
        form_id: item.id
      })
      setIsUnArchiveWarningModalOpen(true)
    }

    const onPublishClick = (e) => {
      setSelectedTemplateTagForm({
        tag_id: tag && tag.id,
        form_id: item.id
      })
      onPublishFormClick(templateTagInt.id, e.target.checked)
    }

    console.log('templateTagInt -----------------', templateTagInt)

    return (
      <div
        style={itemProps}
      >
        <Card
          key={`${card_id}_${index}`}
          title={title || ''}
          imageSrc={imageSrc}
          isDeck={false}
          width={'148px'}
          isHomeComponent={true}
          componentType={component_type}
          home_component_app_content_scopes={[]}
          tabFilter={tabFilter}
          onSettingsClick={onSettingsClick}
          onCardClick={onSettingsClick}
          full_bleed={full_bleed}
          showPublishToggle={true}
          isCopyCard={false}
          onToggleClick={onPublishClick}
          cardType={'single_card'}
          onDeleteCard={onDeleteClick}
          onArchive={onArchiveClick}
          onUnArchive={onUnArchiveClick}
          toggle={templateTagInt?.published || false}
          showArchiveOption={templateTagInt ? true : false}
        />
      </div>
    )
  }

  const renderTagTemplates = (templates, tag) => {
    // console.log('renderTagTemplates', templates)
    return templates && templates.map((itemTemplate, idx) => {
      if (deletedFormList.includes(itemTemplate.template.template_id)) {
        return null;
      }
      // const item = formTemplates?.sharepoint_form_templates?.find(el => el.id === itemTemplate.template_id);
      return (

        <Draggable
          key={itemTemplate.template.template_id}
          draggableId={itemTemplate.template.template_id}
          index={idx}
        >
          {(provided, snapshot) => {
            return (
              <PortalAwareItem
                snapshot={snapshot}
                child={
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                  >
                    <div {...provided.dragHandleProps}>
                      {renderFormCard(itemTemplate.item, idx, "SharePointFormModule", tag, itemTemplate.template)}
                    </div>

                  </div>
                }
              />
            )
          }}
        </Draggable>
      )
    }) || []
  }


  const renderTagsItem = (tag) => {
    const filteredTagTemplates = templateTagIntegrationData && templateTagIntegrationData.find(el => el.tag_id === tag.id);
    // if (!filteredTagTemplates) {
    //   return null
    // }
    const {
      templates = []
    } = filteredTagTemplates || {}
    let filteredTemplates = templates.reduce((acc, el) => {
      let item;
      if (tabFilter === TAB_STATUS.ARCHIVE && el.is_archived) {
        item = formTemplates?.sharepoint_form_templates?.find(el1 => el1.id === el.template_id);
      } else if (tabFilter === TAB_STATUS.ALL && !el.is_archived) {
        item = formTemplates?.sharepoint_form_templates?.find(el1 => el1.id === el.template_id);
      }

      if (item) {
        return ([
          ...acc,
          {
            item,
            template: el,
            position: el.position
          }
        ])
      }
      return acc
    }, []).sort((a, b) => a.position - b.position)
    // console.log("renderTagsItem", filteredTagTemplates, filteredTemplates)
    const onDragEnd = ({ destination, source }) => {
      console.log({ tag, filteredTemplates })
      onCardDragEnd({ destination, source, templates: filteredTemplates, tagId: tag.id })
    }
    return (
      <DragDropContext
        onDragEnd={onDragEnd}
      >
        <Droppable
          droppableId={`droppable_${tag.id}`}
          type={`template_card_row${tag.id}`}
          direction="horizontal"
          isCombineEnabled={false}
        >
          {dropProvided => (
            <div {...dropProvided.droppableProps} ref={dropProvided.innerRef}>
              <div
                className={css({
                  display: 'flex',
                  width: '100%',
                  paddingTop: '20px',
                  paddingBottom: '20px',
                })}
              >
                <div
                  className={css({
                    display: 'flex',
                    flex: 1,
                    width: '100%',
                    overflowX: 'auto',
                    paddingBottom: '10px'
                  })}
                >
                  {renderTagTemplates(filteredTemplates || [], tag)}
                </div>
                {renderAddNewFormTemplate(tag)}
              </div>
            </div>
          )}
        </Droppable>
      </DragDropContext>

    )

  }

  const renderTagsList = () => {
    const filteredTags = selectedTag ? tags && tags.filter(el => el.id === selectedTag.id) : tags;
    return filteredTags.map(tag => {
      const onSettingsClick = () => {
        onOpenTagEditModal(tag)
      }
      return (
        <Cell
          span={12} overrides={cellPaddingOverrides}
        >
          <hr style={{ height: '2px', margin: '2px 0 0', background: 'E2E2E2', marginRight: '-20px', marginLeft: '-20px' }} />
          <div
            className={css({
              display: 'flex',
              paddingTop: '10px',
              paddingBottom: '10px',
              paddingLeft: '10px',
              paddingRight: '10px',
              alignItems: 'center'
            })}
          >

            <LabelMedium
              className={css({
                textTransform: 'capitalize',
                fontFamily: 'Roboto',
                textOverflow: 'ellipsis',
                maxHeight: '24px',
                fontWeight: '600',
                overflow: 'hidden',
                whiteSpace: 'nowrap'
              })}
            >
              {tag.name}
            </LabelMedium>
            <svg
              width="19"
              height="20"
              viewBox="0 0 19 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className={css({
                position: 'relative',
                float: 'left',
                marginLeft: theme.sizing.scale550,
                marginTop: theme.sizing.scale100,
              })}
              onClick={onSettingsClick}
            >
              <path
                d="M16.4731 10.536C16.5091 10.236 16.5331 9.924 16.5331 9.6C16.5331 9.276 16.5091 8.964 16.4611 8.664L18.4891 7.08C18.6691 6.936 18.7171 6.672 18.6091 6.468L16.6891 3.144C16.5691 2.928 16.3171 2.856 16.1011 2.928L13.7131 3.888C13.2091 3.504 12.6811 3.192 12.0931 2.952L11.7331 0.408C11.6971 0.168 11.4931 0 11.2531 0H7.41311C7.17311 0 6.98112 0.168 6.94512 0.408L6.58511 2.952C5.99711 3.192 5.45712 3.516 4.96512 3.888L2.57711 2.928C2.36111 2.844 2.10911 2.928 1.98911 3.144L0.0691144 6.468C-0.0508856 6.684 -0.00288541 6.936 0.189115 7.08L2.21711 8.664C2.16911 8.964 2.13311 9.288 2.13311 9.6C2.13311 9.912 2.15711 10.236 2.20511 10.536L0.177114 12.12C-0.0028857 12.264 -0.0508856 12.528 0.0571144 12.732L1.97711 16.056C2.09711 16.272 2.34911 16.344 2.56511 16.272L4.95311 15.312C5.45711 15.696 5.98511 16.008 6.57311 16.248L6.93312 18.792C6.98111 19.032 7.17311 19.2 7.41311 19.2H11.2531C11.4931 19.2 11.6971 19.032 11.7211 18.792L12.0811 16.248C12.6691 16.008 13.2091 15.684 13.7011 15.312L16.0891 16.272C16.3051 16.356 16.5571 16.272 16.6771 16.056L18.5971 12.732C18.7171 12.516 18.6691 12.264 18.4771 12.12L16.4731 10.536ZM9.33311 13.2C7.35312 13.2 5.73311 11.58 5.73311 9.6C5.73311 7.62 7.35312 6 9.33311 6C11.3131 6 12.9331 7.62 12.9331 9.6C12.9331 11.58 11.3131 13.2 9.33311 13.2Z"
                fill={defaultTheme === "light" ? "#1E1F2C" : "#7A5AF8"}
              />
            </svg>
          </div>
          <hr style={{ height: '2px', margin: '2px 0 0', background: 'E2E2E2', marginRight: '-20px', marginLeft: '-20px' }} />
          {renderTagsItem(tag)}
        </Cell>
      )
    })

  }

  const renderUnTagTemplates = () => {
    const filteredTemplates = formTemplates?.sharepoint_form_templates && formTemplates?.sharepoint_form_templates?.filter(el => {
      const {
        tags: elTags = []
      } = el || {}
      const isFormWOTags = elTags ? elTags.length <= 0 : true;
      if (isFormWOTags && !deletedFormList.includes(el.id)) {
        return el;
      }
    }) || [];
    // console.log('renderUnTagTemplates', filteredTemplates)
    return (
      <Cell
        span={12} overrides={cellPaddingOverrides}
      >
        <hr style={{ height: '2px', margin: '2px 0 0', background: 'E2E2E2', marginRight: '-20px', marginLeft: '-20px' }} />
        <div
          className={css({
            paddingTop: '10px',
            paddingBottom: '10px',
            paddingLeft: '10px',
            paddingRight: '10px'
          })}
        >

          <LabelMedium
            className={css({
              textTransform: 'capitalize',
              fontFamily: 'Roboto',
              textOverflow: 'ellipsis',
              maxHeight: '24px',
              fontWeight: '600',
              overflow: 'hidden',
              whiteSpace: 'nowrap'
            })}
          >
            Templates (No Tags Selected)
          </LabelMedium>
        </div>
        <hr style={{ height: '2px', margin: '2px 0 0', background: 'E2E2E2', marginRight: '-20px', marginLeft: '-20px' }} />
        <div
          className={css({
            display: 'flex',
            width: '100%',
            paddingTop: '20px',
            paddingBottom: '20px',
            overflowX: 'auto'
          })}
        >
          {filteredTemplates.map((el, idx) => {
            return (
              renderFormCard(el, idx, "SharePointFormModule")
            )
          })}
        </div>

      </Cell>
    )

  }

  const renderAddNewFormTemplate = (tag) => {
    const bgColor = defaultTheme === 'dark' ? '#5D64AB' : '#5D64AB';
    const onClick = async () => {
      try {
        setFormCreationTag([tag]);
        setIsFormModalOpen(true)
      } catch (err) {
        console.log('onSaveFormTemplate error', err)
      }
    }
    return (
      <div
        className={css({
          width: '180px !important',
          minWidth: '180px !important',
          height: '250px',
          marginBottom: '5px',
          marginRight: 0,
          boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
          position: 'relative',
          float: 'left',
          border: '2px solid #5D64AB',
          borderRadius: '5px',
          // background: defaultTheme === 'white' ? '#ECEBEB' : 'rgba(74, 76, 160, 0.2)',
          // border: '2px solid rgba(74, 76, 160, 0.2)',
          background: '#FAFAFA',
          border: '3px solid #7A5AF8',
          cursor: 'pointer',
        })}
        onClick={onClick}
      >
        <div
          className={css({
            position: 'relative',
            width: '100%',
            // top: '100%',
            // transform: 'translateY(-50%)',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignContent: 'center',
            justifyContent: 'center'
          })}
        >
          <svg
            width="15"
            height="15.75"
            viewBox="0 0 37 37"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={css({
              position: 'relative',
              left: '50%',
              transform: 'translateX(-50%)',
              cursor: 'pointer',
            })}
          >
            <path
              d="M32.7917 0.125H4.20833C1.94208 0.125 0.125 1.9625 0.125 4.20833V32.7917C0.125 35.0375 1.94208 36.875 4.20833 36.875H32.7917C35.0375 36.875 36.875 35.0375 36.875 32.7917V4.20833C36.875 1.9625 35.0375 0.125 32.7917 0.125ZM28.7083 20.5417H20.5417V28.7083H16.4583V20.5417H8.29167V16.4583H16.4583V8.29167H20.5417V16.4583H28.7083V20.5417Z"
              fill={bgColor}
            />
          </svg>
          <p
            className={`${css({
              textAlign: 'center',
              // marginTop: '72px',
              color: bgColor,
              // fontFamily: 'IBM Plex Sans',
              fontFamily: 'Roboto',
              fontWeight: '700',
              fontSize: '14px',
              // textTransform: 'capitalize',
              maxWidth: '180px !important',
              wordBreak: 'break-all !important',
              marginBottom: '0px',
              overflowX: 'hidden'
            })} dont-break-out`}
          >
            Add Form
          </p>
        </div>
      </div>
    )
  }

  const renderDeletFormModal = () => {
    const onClickYes = () => {
      if (selectedTemplateTagForm.tag_id) {
        setIsDeleteFormModalOpen(false)
        setIsDeletWarningModalOpen(true)
      } else {
        onFormDeleteClick(false)
      }
      console.log({ selectedTemplateTagForm })

    }
    return (
      <Modal
        overrides={{
          Root: {
            style: {
              zIndex: 3,
            }
          }
        }}
        isOpen={isDeleteFormModalOpen}
        onClose={onCloseTemplateTagModel}
        returnFocus={false}
      >
        <ModalHeader>Do you want to delete form template?</ModalHeader>
        <ModalFooter>
          <ModalButton
            onClick={onCloseTemplateTagModel}
          >
            {'Cancel'}
          </ModalButton>
          <ModalButton
            isLoading={isFormDeleting || isFormDeleteLoading}
            onClick={onClickYes}
          >
            {'Delete'}
          </ModalButton>
        </ModalFooter>
      </Modal>
    )
  }

  const renderAddFormModal = () => {
    const onClickSave = () => {
      if (formCreationTag && formCreationTag.length > 0) {
        setIsAddWarningModalOpen(true)
      } else {
        onSaveFormTemplate()
      }
    }
    return (
      <Modal
        overrides={{
          Root: {
            style: {
              zIndex: 3,
            }
          },
          Backdrop: {
            style: ({ $theme }) => ({
              backdropFilter: $theme.modalBackDropFilter,
            }),
          }
        }}
        onClose={onCloseFormModal}
        closeable
        isOpen={isFormModalOpen}
        animate
        autoFocus
        returnFocus={false}
        size={SIZE.default}
        role={ROLE.dialog}
      >
        <ModalHeader>
          <div
            className={css({
              display: 'flex',
            })}
          >
            Add New Form
            <HelpQuestionMark text={card_help}></HelpQuestionMark>
          </div>
        </ModalHeader>
        <ModalBody>
          <Grid
            gridGaps={[12, 12, 24]}
            overrides={{
              Grid: {
                style: ({ $theme }) => outerGridOverride,
              },
            }}
          >
            <Cell
              span={[1, 12]}
              overrides={{
                Cell: {
                  style: () => ({
                    paddingLeft: '0px !important',
                  }),
                },
              }}
            >
              <LabelMedium style={{ marginBottom: '16px' }}>Title</LabelMedium>
              <Input
                value={cardTitle}
                onChange={e => {
                  e.target.value.length <= titlesLength.card && setCardTitle(e.target.value.replace('\n', ''))
                }}
                placeholder="Enter form title"
                clearOnEscape
              // onKeyDown={e =>() => {}
              //   saveModalOnEnterPress({ e, onEnterPress: onSaveInterNal })
              // }
              />
              <ParagraphSmall style={{ marginBottom: '0px' }}>{cardTitle.length}/{titlesLength.card}</ParagraphSmall>
            </Cell>
            <Cell
              span={[1, 12]}
              overrides={{
                Cell: {
                  style: () => ({
                    paddingLeft: '0px !important',
                  }),
                },
              }}
            >
              <LabelMedium style={{ marginBottom: '16px' }}>Description</LabelMedium>
              <Textarea
                value={cardSubTitle}
                onChange={e =>
                  setCardSubTitle(e.target.value.replace('\n', ''))
                }
                placeholder="Enter form description"
                clearOnEscape
              // onKeyDown={e => {}
              //   // saveModalOnEnterPress({ e, onEnterPress: onSaveInterNal })
              // }
              />
            </Cell>
          </Grid>
        </ModalBody>
        <ModalFooter>

          <ModalButton
            isLoading={createFormLoading || isFormCreating}
            onClick={onClickSave}
          >
            Save
          </ModalButton>
        </ModalFooter>
      </Modal>
    )
  }


  const renderImportModal = () => {
    return (
      <Modal
        overrides={{
          Root: {
            style: {
              zIndex: 3,
            }
          }
        }}
        isOpen={isImportModalOpen}
        onClose={onCloseImportModal}
        returnFocus={false}
      >
        <ModalHeader>Import form templates</ModalHeader>
        <ModalBody style={{ flex: '1 1 0' }}>
          {importFile && <LabelMedium>File Selected {importFile.name}</LabelMedium>}
          <FileUploader
            onDrop={(acceptedFiles, rejectedFiles) => {
              setImportFile(acceptedFiles[0])

            }}
          />
        </ModalBody>
        <ModalFooter>
          <ModalButton
            isLoading={importLoading}
            disabled={!importFile}
            onClick={onClickImport}
          >
            {'Import'}
          </ModalButton>
        </ModalFooter>
      </Modal>
    )
  }

  const renderAdvanceModal = () => {
    const onImportClick = () => {
      setIsDefaultValuseSetUploaderOpen(true)
    }
    return (
      <Modal
        overrides={{
          Root: {
            style: {
              zIndex: 3,
            }
          }
        }}
        isOpen={isAdvanceSettingOpen}
        onClose={onCloseAdvanceSettingModal}
        returnFocus={false}
      >
        <ModalHeader>Advanced Form template settings</ModalHeader>
        <ModalBody style={{ display: 'flex', flexDirection: 'column' }}>
          <Button
            className={css({
              fontSize: '14px !important'
            })}
            isSelected
            overrides={buttonOverrides}
            onClick={onExportFormDetails}
          >
            Export template as .xls
          </Button>
          <Button
            className={css({
              fontSize: '14px !important'
            })}
            isSelected
            overrides={buttonOverrides}
            onClick={onImportClick}
          >
            Import .xls file into templates
          </Button>
        </ModalBody>
        <ModalFooter>
        </ModalFooter>
      </Modal>
    )
  }

  const renderImportDefaultValueSetModal = () => {
    return (
      <Modal
        overrides={{
          Root: {
            style: {
              zIndex: 3,
            }
          }
        }}
        isOpen={isDefaultValuseSetUploaderOpen}
        onClose={onCloseDefaultValuseSetModal}
        returnFocus={false}
      >
        <ModalHeader>Import form template default value set</ModalHeader>
        <ModalBody style={{ flex: '1 1 0' }}>
          <Checkbox
            checked={importExcelType}
            onChange={e => setImportExcelType(!importExcelType)}
            labelPlacement={LABEL_PLACEMENT.right}
          >
            <ParagraphSmall style={{ marginTop: '2px', marginBottom: '10px' }}>Do you want to append default value sets</ParagraphSmall>
          </Checkbox>
          {importExcelFile && (
            <LabelMedium
              className={css({
                marginBottom: '10px'
              })}
            >
              File Selected: {importExcelFile.name}
            </LabelMedium>
          )}
          <FileUploader
            onDrop={(acceptedFiles, rejectedFiles) => {
              setImportExcelFile(acceptedFiles[0])

            }}
          />
        </ModalBody>
        <ModalFooter>
          <ModalButton
            isLoading={importLoading}
            disabled={!importExcelFile}
            onClick={onImportFormDefaultValSet}
          >
            {'Import'}
          </ModalButton>
        </ModalFooter>
      </Modal>
    )
  }

  const renderHeader = () => {
    const SelectedTagValue = selectedTag && [{
      id: selectedTag?.id,
      name: selectedTag?.name
    }] || []
    return (
      <Cell overrides={cellPaddingOverrides} span={12} >
        <div style={{
          display: 'flex',
          width: '100%',
          justifyContent: 'space-between',
          alignItems: 'center',
          paddingLeft: '10px',
          paddingRight: '10px',
          paddingBottom: '30px'
        }}>
          <div
            style={{
              width: '35%'
            }}
          >
            <Select
              options={tags}
              value={SelectedTagValue}
              labelKey={'name'}
              valueKey={'id'}
              placeholder="Select Tag"
              onChange={(params) => {
                console.log('params.option', params.option)
                setSelectedTag(params.option)
              }}
            />
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column'
            }}
          >
            <div
              className={css({
                display: 'flex',
                marginBottom: '25px',
                justifyContent: 'flex-end'
              })}
            >
              <StatefulButtonGroup
                mode={MODE.radio}
                initialState={{ selected: 0 }}
              >
                <Button
                  className={css({
                    fontSize: '14px !important'
                  })}
                  overrides={buttonOverrides}
                  onClick={() => setTabFilter(TAB_STATUS.ALL)}>All</Button>

                <Button
                  className={css({
                    fontSize: '14px !important'
                  })}
                  overrides={buttonOverrides}
                  onClick={() => setTabFilter(TAB_STATUS.ARCHIVE)}>Archived</Button>
                {/* <Button onClick={() => setTab(TAB_STATUS.DESIGN)}>Design</Button> */}
              </StatefulButtonGroup>
            </div>
            <div
              className={css({
                display: 'flex',
                flexDirection: 'row'
              })}
            >
              <AddContentButton
                buttonText={'Advanced'}
                helpText={`Click to import export form default value set`}
                buttonClickOverride={onClickAdvanceModal}
                style={{
                  marginRight: '10px'
                }}
              />
              <AddContentButton
                buttonText={'Import'}
                helpText={`Click to import form`}
                buttonClickOverride={onClickImportModal}
                style={{
                  marginRight: '10px'
                }}
              />
              <AddContentButton
                buttonText={'Form'}
                helpText={`Click to add new form`}
                buttonClickOverride={onClickAddCard}

              />
            </div>

          </div>
        </div>
      </Cell>
    )
  }


  const DeleteTemplateChildsModalWarning = () => {
    const onClickYes = () => {
      onFormDeleteClick(true)
    }

    const onClickNo = () => {
      onFormDeleteClick(false)
    }

    return (
      <Modal
        overrides={{
          Root: {
            style: {
              zIndex: 3,
            }
          }
        }}
        onClose={onCloseDeleteWarningModal}
        autoFocus={false}
        isOpen={isDeletWarningModalOpen}
      // closeable={false}
      >
        <ModalHeader>Do you want to delete template from channels where it is imported?</ModalHeader>
        <ModalFooter>
          <ModalButton
            onClick={onClickYes}
            isLoading={isFormDeleting || isFormDeleteLoading}
          >
            Yes
          </ModalButton>
          <ModalButton
            onClick={onClickNo}
            isLoading={isFormDeleting || isFormDeleteLoading}
          >
            No
          </ModalButton>
        </ModalFooter>
      </Modal>
    )
  }

  const ArchiveTemplateChildsModalWarning = () => {
    const onClickYes = async () => {
      await onFromArchiveClick(true, true)
    }

    const onClickNo = async () => {
      await onFromArchiveClick(true, false)
    }

    return (
      <Modal
        overrides={{
          Root: {
            style: {
              zIndex: 3,
            }
          }
        }}
        onClose={onCloseArchiveWarningModal}
        autoFocus={false}
        isOpen={isArchiveWarningModalOpen}
      // closeable={false}
      >
        <ModalHeader>Do you want to archive template from channels where it is imported?</ModalHeader>
        <ModalFooter>
          <ModalButton
            onClick={onClickYes}
            isLoading={isFormArchiving || isFormArchiveLoading}
          >
            Yes
          </ModalButton>
          <ModalButton
            onClick={onClickNo}
            isLoading={isFormArchiving || isFormArchiveLoading}
          >
            No
          </ModalButton>
        </ModalFooter>
      </Modal>
    )
  }

  const UnArchiveTemplateChildsModalWarning = () => {
    const onClickYes = async () => {
      await onFromArchiveClick(false, true)
    }

    const onClickNo = async () => {
      await onFromArchiveClick(false, false)
    }

    return (
      <Modal
        overrides={{
          Root: {
            style: {
              zIndex: 3,
            }
          }
        }}
        onClose={onCloseUnArchiveWarningModal}
        autoFocus={false}
        isOpen={isUnArchiveWarningModalOpen}
      // closeable={false}
      >
        <ModalHeader>Do you want to Unarchive template from channels where it is imported?</ModalHeader>
        <ModalFooter>
          <ModalButton
            onClick={onClickYes}
            isLoading={isFormArchiving || isFormArchiveLoading}
          >
            Yes
          </ModalButton>
          <ModalButton
            onClick={onClickNo}
            isLoading={isFormArchiving || isFormArchiveLoading}
          >
            No
          </ModalButton>
        </ModalFooter>
      </Modal>
    )
  }


  // console.log('templateTagIntegration', { templateTagIntegration, tags, tabFilter })

  return (
    <>
      <Grid overrides={{
        Grid: {
          style: () => ({
            padding: '0px !important',
            marginTop: '-50px',
            marginRight: '12px',
            marginLeft: '12px'
          }),
        },
      }}>
        {renderHeader()}
        {renderTagsList()}
        {(tabFilter === TAB_STATUS.ALL) && renderUnTagTemplates()}
      </Grid>
      {renderImportModal()}
      {renderAddFormModal()}
      {renderDeletFormModal()}
      {renderAdvanceModal()}
      {renderImportDefaultValueSetModal()}
      {
        showEditModal && (
          <FormBuilderHomeComponent
            organization_id={organization_id}
            refetchHomeComponents={fetchAllTemplates}
            selectedComponentType={currentComponentConfig}
            selectedModule={selectedModule}
            onClose={() => { setShowEditModal(false) }}
            updateMutation={updateSharePointFormModuleTemplate}
            isTemplate={true}
          ></FormBuilderHomeComponent>
        )
      }
      {
        <UpdateTagModal
          organization_id={organization_id}
          tag={selectedEditTag}
          tabFilter={tabFilter}
          isOpen={isTagEditModalOpen}
          onUpdate={onUpdateTagEditModal}
          onClose={onCloseTagEditModal}
        />
      }
      <AddTemplateChildsModalWarning
        isOpen={isAddWarningModalOpen}
        onSave={onSaveFormTemplate}
        onChangePublish={setPublishChildComponents}
        isLoading={createFormLoading || isFormCreating}
        onClose={onCloseAddWarningModal}
        isPublish={publish_child_components}
      />
      <DeleteTemplateChildsModalWarning />
      <ArchiveTemplateChildsModalWarning />
      <UnArchiveTemplateChildsModalWarning />
    </>
  )
}

export default SharepointFormTemplates;