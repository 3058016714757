import * as React from 'react';
import { Grid, Cell } from 'baseui/layout-grid';
import Gallery from 'react-grid-gallery';
import { ParagraphSmall, LabelXSmall, HeadingXSmall, HeadingLarge, HeadingMedium, LabelSmall, HeadingSmall } from 'baseui/typography';
import { Tag, KIND, VARIANT } from 'baseui/tag';
import { Button as BaseButton, SIZE } from 'baseui/button';
import PreviewElements from '../builder/previewElements';
import moment from 'moment';
import { sourceByType } from '../../util';
import styled from '@emotion/styled';
import { useStyletron } from 'baseui';
import { gridPaddingOverrides } from '../overrides';

const communicationTypes = {
  PUSH: 'Push',
  EMAIL: 'Email',
  NEWS: 'News',
  POPUP: 'Popup',
}

const getSrc = (data, type) => sourceByType(data, type);

const TypeButton = styled(BaseButton)`
  background-color: #c4c4c4;
  color: white;
  padding-left: 20px;
  padding-right: 20px;
  height: 30px;
  line-height: 30px;
  margin-top: 16px;
  border-radius: 2px;
`;

const detailBtnContainerStyle = {
  float: 'left',
  position: 'relative',
  width: '100%',
  display: 'flex',
  justifyContent: 'flex-end',
  backgroundColor: 'transparent',
  color: '#7A5AF8'
};

const detailBtnStyle = {
  backgroundColor: 'transparent',
  color: '#7A5AF8',
  padding: 0,
  fontWeight: 700,
  fontSize: '12px'
};

const getNotificationTypeCSS = (name) => {
  var css = {};
  css.backgroundColor = name === communicationTypes.PUSH && '#7A5AF8' || name === communicationTypes.EMAIL && '#EE4678' || name === communicationTypes.NEWS && '#0D0E13' || '#c4c4c4';
  css.color = "white";
  css.paddingLeft = "10px";
  css.paddingRight = "10px";
  // css.height = "30px";
  // css.lineHeight = "30px";
  // css.marginTop = "16px";
  css.borderRadius = "2px";
  return css;
};

var defaultContainerStyle = {
  width: '100%',
  position: 'relative',
  padding: '14px',
  borderRadius: '5px',
  marginTop: '32px',
  // display: 'flex',
}

const renderCommunicationType = (name) => {
  return (
    <div
      style={{
        marginLeft: '16px',
        float: 'right',
        position: 'relative',
        display: 'flex',
        textAlign: 'center',
        alignItems: 'center',
        fontSize: '14px',
        ...getNotificationTypeCSS(name)
      }}
    >
      {name}
    </div>
  )
}



const FeedItem = ({
  feed,
  key,
  defaultTheme,
  front_image,
  dataType = 'originalDBData',
  onArchive,
  onDetail,
  tabFilter,
}) => {
  console.log(feed);
  const [css, theme] = useStyletron();
  const icon = front_image || getSrc(feed.communication.front_image_data);
  const largeIcon =
    front_image || getSrc(feed.communication.front_image_data, 'small');
  const [isViewerOpen, setIsViewerOpen] = React.useState(false);
  const archived = feed.communication.archived || false;

  let containerStyle = {
    ...defaultContainerStyle,
    background: defaultTheme === 'dark' ? theme.colors.primary700 : '#EAEAEA',
    ...(archived
      ? {
        opacity: 0,
        width: 0,
        height: 0,
        transition: 'width 0.5s 0.5s, height 0.5s 0.5s, opacity 0.5s',
        padding: '0px',
        margin: '0px',
      }
      : {}),
    marginTop: key === 0 ? 0 : '10px'
  };

  // ------------------------------------------------------------------------------------------- //

  const getChannelNamesText = (channelNames) => {
    return channelNames.map((channel, idx) => {
      let txt = channel.name;
      if (idx !== (channelNames.length - 1)) {
        txt = `${txt}, `;
      }
      return txt;
    })
  }

  // ------------------------------> Render Function <------------------------------------------ //

  const renderSubRow = (LeftComp, RightComp) => {
    return (
      <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
        <div
          style={{
            display: 'flex',
            flex: 1,
          }}
        >
          {LeftComp && LeftComp()}
        </div>
        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
          }}
        >
          {RightComp && RightComp()}
        </div>
      </div>

    )
  }

  const renderCommName = () => {
    return (
      <HeadingSmall
        className={css({
          marginBottom: '0px !important',
        })}
      >
        {feed.communication.title}
      </HeadingSmall>
    )
  }

  const renderCommTypes = () => {
    return (
      <>
        {feed.communication.type_push_notification && renderCommunicationType(communicationTypes.PUSH)}
        {feed.communication.type_email && renderCommunicationType(communicationTypes.EMAIL)}
        {feed.communication.type_news && renderCommunicationType(communicationTypes.NEWS)}
        {feed.communication.type_popup && renderCommunicationType(communicationTypes.POPUP)}
      </>
    )
  }

  const renderCommPostTo = () => {
    let channelNames = feed.app_content_scope;
    if (feed.app_content_scope && !Array.isArray(feed.app_content_scope)) {
      channelNames = [channelNames]
    }
    return (
      <LabelSmall >
        {`Posted to: ${getChannelNamesText(channelNames)}`}
      </LabelSmall>
    )
  }
  const renderCommPosByAt = () => (
    <LabelSmall >
      {feed.communication.created_by_name || 'User'} created this at{' '}
      {moment(feed.communication.created_at).format('llll')}
    </LabelSmall>
  )

  const renderButtonContainer = () => {
    const onDetailsClick = () => {
      // console.log('coming soon ... ');
      onDetail(feed)
    }

    const onArchiveClick = () => {
      onArchive(feed.communication_id);
    }

    return (
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly' }}>
        <div
          style={{ ...detailBtnContainerStyle, width: '100px' }}
        >
          <BaseButton
            style={detailBtnStyle}
            onClick={onDetailsClick}
          >
            Details
          </BaseButton>
        </div>
        {
          tabFilter != 'Archives' && (

            <div
              style={detailBtnContainerStyle}
            >
              <BaseButton
                onClick={onArchiveClick}
                style={detailBtnStyle}
              >
                Archive
              </BaseButton>
            </div>
          )
        }

      </div>
    )
  }

  const renderTag = (tag) => (
    <div
      className={css({
        position: 'relative',
        float: 'left',
      })}
    >
      <Tag
        kind={KIND.primary}
        closeable={false}
        variant={VARIANT.solid}
        overrides={{
          Tag: {
            style: ({ $theme }) => ({
              margin: '0px !important',
            }),
          },
          Root: {
            style: ({ $theme }) => ({
              paddingLeft: "15px",
              paddingRight: "15px"
            })
          }
        }}
      >
        {tag}
      </Tag>
    </div>

  )
  const renderTagsList = () => (
    (feed.communication.tags || []).map(renderTag)
  )
  return (
    <div style={containerStyle}>
      <Grid overrides={gridPaddingOverrides} width="100%" height="100%" style={{ backgroundColor: 'yellow' }}>
        <Cell span={1}>
          <div >
            <Gallery
              images={[
                {
                  caption: '',
                  src: largeIcon,
                  thumbnail: icon,
                  thumbnailWidth: 24,
                  thumbnailHeight: 24,
                  padding: 0
                },
              ]}
              rowHeight={63}
            />
            <LabelXSmall
              style={{
                fontWeight: 400,
                fontSize: '8px',
                marginTop: '10px',
                marginLeft: '4px',
                position: 'relative',
                float: 'left',
                width: '100%',
              }}
              onClick={() => {
                // setIsViewerOpen(true);
              }}
            >
              View full image
            </LabelXSmall>
          </div>
        </Cell>
        <Cell span={11} >
          {
            renderSubRow(
              renderCommName,
              renderCommTypes
            )
          }
          <div style={{ height: '16px' }} />
          {
            renderSubRow(
              renderCommPostTo,
              renderCommPosByAt
            )
          }
          <div style={{ height: '16px' }} />
          {
            renderSubRow(
              renderTagsList,
              renderButtonContainer
            )
          }
        </Cell>
      </Grid>
    </div>
  )
  // return (
  //   <Cell span={12} key={key}>
  //     <div
  //       style={{
  //         width: '100%',
  //         position: 'relative',
  //         padding: '16px',
  //         background:
  //           defaultTheme === 'dark' ? theme.colors.primary700 : '#EAEAEA',
  //         borderRadius: '5px',
  //         marginTop: '32px',
  //         display: 'flex',
  //         ...(archived
  //           ? {
  //             opacity: 0,
  //             width: 0,
  //             height: 0,
  //             transition: 'width 0.5s 0.5s, height 0.5s 0.5s, opacity 0.5s',
  //             padding: '0px',
  //             margin: '0px',
  //           }
  //           : {}),
  //       }}
  //     >
  //       {/* logo */}
  //       <div style={{ width: '92px' }}>
  //         {/* <img src={icon} style={{ width: '92px', marginBottom: '0px' }}></img> */}
  //         <Gallery
  //           images={[
  //             {
  //               caption: '',
  //               src: largeIcon,
  //               thumbnail: icon,
  //               thumbnailWidth: 24,
  //               thumbnailHeight: 24,
  //             },
  //           ]}
  //           rowHeight={92}
  //         />
  //         <LabelXSmall
  //           style={{
  //             fontWeight: 400,
  //             marginLeft: '4px',
  //             position: 'relative',
  //             float: 'left',
  //             width: '100%',
  //           }}
  //           onClick={() => {
  //             // setIsViewerOpen(true);
  //           }}
  //         >
  //           View full image
  //         </LabelXSmall>
  //       </div>
  //       {/* Content */}
  //       <div
  //         style={{
  //           marginLeft: '18px',
  //           float: 'left',
  //           width: 'calc(100% - 110px)',
  //         }}
  //       >
  //         <div style={{ position: 'relative', width: '50%', float: 'left' }}>
  //           <HeadingXSmall
  //             className={css({
  //               marginBottom: '0px !important',
  //             })}
  //           >
  //             {feed.communication.title}
  //           </HeadingXSmall>
  //           <LabelXSmall style={{ color: '#7A5AF8' }}>
  //             {feed.communication.created_by_name || 'User'} created this on{' '}
  //             {moment(feed.communication.created_at).format('llll')}
  //           </LabelXSmall>
  //           {(feed.communication.tags || []).map(tag => (
  //             <div
  //               className={css({
  //                 position: 'relative',
  //                 float: 'left',
  //               })}
  //             >
  //               <Tag
  //                 kind={KIND.primary}
  //                 closeable={false}
  //                 variant={VARIANT.solid}
  //                 overrides={{
  //                   Tag: {
  //                     style: ({ $theme }) => ({
  //                       margin: '0px !important',
  //                     }),
  //                   },
  //                 }}
  //               >
  //                 {tag}
  //               </Tag>
  //             </div>
  //           ))}
  //         </div>
  //         <div style={{ float: 'left', position: 'relative', width: '50%' }}>
  //           {feed.communication.type_news && (
  //             <p
  //               style={{
  //                 marginLeft: '16px',
  //                 float: 'right',
  //                 position: 'relative',

  //                 ...getNotificationTypeCSS()
  //               }}

  //             >
  //               News
  //             </p>
  //           )}
  //           {feed.communication.type_email && (
  //             <p
  //               style={{
  //                 marginLeft: '16px',
  //                 float: 'right',
  //                 position: 'relative',

  //                 ...getNotificationTypeCSS()
  //               }}
  //             >
  //               Email
  //             </p>
  //           )}
  //           {feed.communication.type_push_notification && (
  //             <p
  //               style={{
  //                 marginLeft: '16px',
  //                 float: 'right',
  //                 position: 'relative',

  //                 ...getNotificationTypeCSS()
  //               }}
  //             >
  //               Push Notification
  //             </p>
  //           )}
  //         </div>
  //         {/* {feed.communication.message_body && (
  //           <div style={{ float: 'left', position: 'relative', width: '100%' }}>
  //             {feed.communication.message_body && (
  //               <div
  //                 className={css({
  //                   marginBottom: '16px',
  //                 })}
  //               >
  //                 <ParagraphSmall>
  //                   {feed.communication.message_body}
  //                 </ParagraphSmall>
  //               </div>
  //             )}
  //           </div>
  //         )} */}
  //         {/* <div style={{ float: 'left', position: 'relative', width: '100%' }}>
  //           {feed.communication.card_content_modules && (
  //             <div
  //               className={css({
  //                 marginBottom: '16px',
  //               })}
  //             >
  //               <PreviewElements
  //                 cardBackData={feed.communication.card_content_modules}
  //                 dataType={dataType}
  //               ></PreviewElements>
  //             </div>
  //           )}
  //         </div> */}
  //         {/* {tabFilter != 'Archives' && (
  //           <div
  //             style={{
  //               float: 'left',
  //               position: 'relative',
  //               width: '100%',
  //               display: 'flex',
  //               justifyContent: 'flex-end',
  //             }}
  //           >
  //             <BaseButton
  //               size={SIZE.mini}
  //               onClick={() => onArchive(feed.communication_id)}
  //             >
  //               Archive
  //             </BaseButton>
  //           </div>
  //         )} */}
  //       </div>
  //     </div>
  //   </Cell >
  // );
};

export default FeedItem;
